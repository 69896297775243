
import { Box, Typography } from '@mui/material';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

export const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Box sx={{ display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'center', 
        bgcolor: '#A80000', 
        borderRadius: '8px', 
        p: '8px', 
        height: '32px', 
        width: 'auto', 
        alignItems: 'center'
      }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '12px', color: 'white', lineHeight: 1.2, textDecoration: 'underline' }} >{label}</Typography>
        <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: '14px', color: 'white', lineHeight: 1.2  }}>${payload[0].value.toFixed(2)}</Typography>
      </Box>
    );
  }

  return null;
};

function EarningsGraph(props) {

    const data = props.data

    const formatter = (value) => `$${value}`

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
              height="100%"
              data={data}
              margin={{
                top: 5,
                right: 25,
                left: -5,
                bottom: 5,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis dataKey="month" type="category" />
              <YAxis tickFormatter={formatter} />
              <Tooltip content={<CustomTooltip />} itemStyle={{ color: 'black' }} cursor={{fill: 'transparent'}} separator={": "} />
              <Bar dataKey="amt" fill="white" radius={[6, 6, 0, 0]} activeBar={<Rectangle fill="#191919" stroke="#A80000" />} />
            </BarChart>
        </ResponsiveContainer>
    );
}

export default EarningsGraph;