import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { DataGrid } from '@mui/x-data-grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';

import { adminGetDeposits } from '../APIs';

import { supabase } from '../auth/client';

function AdminDepositsDashboard(props) {

    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
        },
    });

    const [depositsData, setDepositsData] = useState(null);

    // used for skeleton components when fetching data from backend
    const [isLoading, setIsLoading] = useState(true);

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    });

    const fetchDepositsData = async () => {

        setIsLoading(true)

        const userData = {
            token: props.adminSession.access_token
        }

        const data = await adminGetDeposits(userData)
        if (data.status === 401) {
            props.setAdminSession(null)
            const { error } = await supabase.auth.signOut()
            return
        } else if (data.status !== 200) {
          return
        }
        setDepositsData(data.deposits)
        setIsLoading(false)
        return
    }

    useEffect(() => {
        fetchDepositsData()
    }, [])

    const rows = depositsData

    const columns = [
        {   
            field: '_id', 
            headerName: 'id', 
            width: 240,
        },
        {
            field: 'userID',
            headerName: 'userID',
            width: 240,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'email',
            width: 240,
            editable: false,
        },
        {
            field: 'firstName',
            headerName: 'firstName',
            width: 160,
            editable: false,
        },
        {
            field: 'lastName',
            headerName: 'lastName',
            width: 160,
            editable: false,
        },
        {
            field: 'amount',
            headerName: 'amount',
            width: 100,
            type: 'number',
            editable: false,
        },
        {
            field: 'time',
            headerName: 'time',
            width: 200,
            editable: false,
        },
    ];
        
    return (
        <Box sx={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'start', boxSizing: 'border-box', flexDirection: 'column' }}>
            {!isLoading ? 
                <ThemeProvider theme={darkTheme}>
                    {depositsData ? 
                        <Box sx={{ display: 'flex', width: '80%', gap: '24px', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'center', alignSelf: 'center', mt: '16px' }}>
                            <Box sx={{ height: 631, width: '100%', bgcolor: '#191919' }}>
                                <DataGrid
                                    sx={{ color: 'white', 
                                        '& .MuiDataGrid-filler': {
                                            backgroundColor: '#191919',
                                        }, 
                                        '& .MuiDataGrid-scrollbar--horizontal': {
                                            left: 0
                                        },
                                        '--DataGrid-containerBackground': '#191919'
                                    }}
                                    rows={rows}
                                    getRowId={(row) => row._id}
                                    columns={columns}
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={setPaginationModel}
                                    editMode='row'
                                />
                            </Box>
                        </Box>
                    :
                        null
                    }
                </ThemeProvider>
            :
                <Skeleton variant="rounded"  width='80%' height='75%' sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', alignSelf: 'center', mt: '16px' }} />
            }
        </Box>
    )
}

export default AdminDepositsDashboard