import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';

import { PaymentInputsContainer } from 'react-payment-inputs';
import { Typography } from '@mui/material';

export default function PaymentInputs(props) {

    const [showError, setShowError] = useState(false)

    const handleCardNumberChange = (event) => {
        props.setCardNumber(event.target.value)
    }

    const handleExpiryChange = (event) => {
        props.setExpiry(event.target.value)
    }

    const handleCVCChange = (event) => {
        props.setCVC(event.target.value)
    }

    useEffect(() => {
        if (!props.cardNumber && !props.expiry && !props.cvc) {
            setShowError(false)
        } else {
            setShowError(true)
        }
    }, [props.cardNumber, props.expiry, props.cvc])

    return (
        <PaymentInputsContainer>
        {({ meta, getCardNumberProps, getExpiryDateProps, getCVCProps }) => (
            <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', gap: {xs:'6px', sm:'8px'}, height: {xs:'290px', sm: '300px'}, mt: '8px' }}>
                <Typography sx={{ fontFamily: 'Montserrat', textShadow: '1px 1px 1px black', fontWeight: 500, fontSize: '16px', color: 'white', alignSelf: 'start' }}>
                    Card Number
                </Typography>
                <input className='payment-input' style={{ 
                        fontFamily: 'Montserrat',
                        fontWeight: 500,
                        fontSize: '14px',
                        color: 'white',
                        borderRadius: '8px',
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        border: '1px solid rgba(255, 255, 255, 0.2)',
                        boxSizing: 'border-box',
                        padding: '4px 16px 4px 16px',
                    }}
                    {...getCardNumberProps({ onChange: handleCardNumberChange })} value={props.cardNumber} 
                />
                <Typography sx={{ fontFamily: 'Montserrat', textShadow: '1px 1px 1px black', fontWeight: 500, fontSize: '16px', color: 'white', alignSelf: 'start' }}>
                    Expiration Date
                </Typography>
                <input className='payment-input' style={{ 
                        fontFamily: 'Montserrat',
                        fontWeight: 500,
                        fontSize: '14px',
                        color: 'white',
                        borderRadius: '8px',
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        border: '1px solid rgba(255, 255, 255, 0.2)',
                        boxSizing: 'border-box',
                        padding: '4px 16px 4px 16px',
                    }}
                    {...getExpiryDateProps({ onChange: handleExpiryChange })} value={props.expiry} 
                />
                <Typography sx={{ fontFamily: 'Montserrat', textShadow: '1px 1px 1px black', fontWeight: 500, fontSize: '16px', color: 'white', alignSelf: 'start' }}>
                    CVC
                </Typography>
                <input className='payment-input' style={{ 
                        fontFamily: 'Montserrat',
                        fontWeight: 500,
                        fontSize: '14px',
                        color: 'white',
                        borderRadius: '8px',
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        border: '1px solid rgba(255, 255, 255, 0.2)',
                        boxSizing: 'border-box',
                        padding: '4px 16px 4px 16px',
                    }}
                    {...getCVCProps({ onChange: handleCVCChange })} value={props.cvc} 
                />
                {showError && meta.isTouched && meta.error && <span className="payment-error">{meta.error}</span>}
            </Box>
        )}
        </PaymentInputsContainer>
    );
}