import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import AmountInput from './AmountInput';
import PaymentInputs from './PaymentInputs';
import { deposit } from '../APIs';

import { supabase } from '../auth/client';

import { useAcceptJs } from 'react-acceptjs';

const authData = {
  apiLoginID: process.env.REACT_APP_AUTHORIZE_API_LOGIN_ID,
  clientKey: process.env.REACT_APP_CLIENT_KEY,
};

const environment = 'PRODUCTION'

function CreditCardDialog(props) {
    

    const { dispatchData, loading, error } = useAcceptJs({ environment, authData });

    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
        },
    });
    const navigate = useNavigate()

    const [amount, setAmount] = useState('20')
    const [cardNumber, setCardNumber] = useState("")
    const [expiry, setExpiry] = useState("")
    const [cvc, setCVC] = useState("")

    const [errorMsg, setErrorMsg] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async () => {

        setIsLoading(true)

        const [expMonth, expYear] = expiry.split(' / ');

        const cardData = {
            cardNumber: cardNumber.replace(/\s+/g, ''),
            month: expMonth,
            year: expYear,
            cardCode: cvc,
        }

        if (isNaN(parseFloat(amount))) {
            setErrorMsg({type: 'amount', msg: 'Invalid deposit amount'})
            setIsLoading(false)
            return
        }

        if (parseFloat(amount) < 10) {
            setErrorMsg({type: 'amount', msg: 'Minimum deposit amount is $10'})
            setIsLoading(false)
            return
        }

        if (parseFloat(amount) > 100) {
            setErrorMsg({type: 'amount', msg: 'Maximum deposit amount is $100'})
            setIsLoading(false)
            return
        }

        if (parseFloat(amount) >= 10 && parseFloat(amount) <= 100) {

            let response
            try {
                response = await dispatchData({ cardData })
            } catch (error) {
                setErrorMsg({type: 'other', msg: error?.messages?.message[0]?.text})
                setIsLoading(false)
                return
            }
            const paymentNonce = response.opaqueData.dataValue;
            const userData = {
                amount: amount,
                paymentNonce: paymentNonce,
                token: props.session.access_token
            }
            const response2 = await deposit(userData)
    
            if (response2.status === 401) {
                props.setSession(null)
                const { error } = await supabase.auth.signOut()
                navigate('/login')
                return
            } else if (response2.status === 422) {
                setErrorMsg(response2.errorObj)
                setIsLoading(false)
                return
            } else if (response2.status === 400) {
                setErrorMsg(response2.errorObj)
                setIsLoading(false)
                return
            } else if (response2.status === 429) {
                setErrorMsg({type: "other", msg: "Too many requests. Please try again in a few minutes"})
                setIsLoading(false)
                return
            } else if (response2.status !== 200) {
                setErrorMsg({type: "other", msg: "Server error"})
                setIsLoading(false)
                return
            }
    
            if (response2.success === false) {
                props.setSuccess(-1)
                setIsLoading(false)
                handleClose()
                return
            }
    
            props.setSuccess(1)
            setIsLoading(false)
            handleClose()

        } else {
            setErrorMsg({type: 'amount', msg: 'Invalid deposit amount'})
            setIsLoading(false)
            return
        }
    }
    
    const handleClose = () => {
        props.setOpen(false)
        setAmount('20')
        setCardNumber("")
        setExpiry("")
        setCVC("")
        setErrorMsg({})
        setIsLoading(false)
    }

    // Check if value is empty, null, undefined, or NaN
    const isValidValue = (value) => {
        return value !== null && value !== undefined && value !== '' && !Number.isNaN(value);
    }

    useEffect(() => {
        if (Object.keys(errorMsg).length > 0 && errorMsg.type === "other") {
          const timer = setTimeout(() => {
            setErrorMsg({})
          }, 5000)

          return () => clearTimeout(timer);
        }
    }, [errorMsg])


    return (
        <ThemeProvider theme={darkTheme}>
            {errorMsg && errorMsg.msg && errorMsg.type == 'other' ? 
                <Alert sx={{ 
                    position: 'fixed', top: '72px', width: {xs:'80%', md:'auto'}, 
                    zIndex: 99, bgcolor: '#191919', 
                    border: '2px solid #d32f2f', color: 'white', 
                    fontFamily: 'Poppins', fontSize: {xs:'14px', sm:'16px'}, fontWeight: 500,
                    alignSelf: 'center', lineHeight: 1.2, alignItems: 'center'
                }} severity="error" >
                    {errorMsg?.msg}
                </Alert> 
            : null }
            <Dialog
                open={props.open}
                onClose={handleClose}
                fullWidth
            >
                <DialogTitle sx={{
                    fontFamily: 'Poppins',
                    fontWeight: 500,
                    fontSize: '24px',
                    color: 'white',
                    p: '16px',
                    alignSelf: 'center',
                    textShadow: '2px 2px 3px black',
                    letterSpacing: 0.6,
                    lineHeight: 1.1,
                    textAlign: 'center'
                }}>
                    DEPOSIT FUNDS
                </DialogTitle>
                <DialogContent sx={{ minWidth: {xs:'240px', sm: '500px', md: '600px'}, boxSizing: 'border-box', px: {xs:'16px', sm:'64px'} }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', boxSizing: 'border-box', gap: '8px' }}>
                        <Typography sx={{ fontFamily: 'Montserrat', textShadow: '1px 1px 1px black', fontWeight: 500, fontSize: '16px', color: 'white', alignSelf: 'start' }}>
                            Deposit Amount
                        </Typography>
                        <AmountInput amount={amount} setAmount={setAmount} />
                        {errorMsg && errorMsg.msg && errorMsg.type == 'amount' ? 
                            <Typography sx={{
                                fontWeight: 600,
                                color: '#d32f2f',
                                fontFamily: 'Montserrat',
                                fontSize: '15px',
                                textAlign: 'center',
                                lineHeight: 1.2
                            }}>
                                {errorMsg.msg}
                            </Typography>
                        :
                            null
                        }
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', boxSizing: 'border-box', gap: {xs: '8px', sm: '8px'}, mt: '12px', flexWrap: 'wrap' }}>
                        <Button variant="contained" onClick={() => setAmount('20')} sx={{ 
                            bgcolor: parseFloat(amount) === 20 ? 'white' : '#191919', 
                            color: parseFloat(amount) === 20 ? 'black' : 'white',
                            width: {xs:'20%', sm:'23%'},
                            height: {xs:'42px', sm:'48px'},
                            '&:hover': {
                                backgroundColor: 'white',
                                color: 'black'
                            },
                            fontFamily: 'Poppins',
                            fontWeight: parseFloat(amount) === 20 ? 600: 400,
                            fontSize: '16px',
                            borderRadius: '8px',
                            boxSizing: 'border-box',
                        }}>
                            $20
                        </Button>
                        <Button variant="contained" onClick={() => setAmount('50')} sx={{ 
                            bgcolor: parseFloat(amount) === 50 ? 'white' : '#191919', 
                            color: parseFloat(amount) === 50 ? 'black' : 'white',
                            width: {xs:'20%', sm:'23%'},
                            height: {xs:'42px', sm:'48px'},
                            '&:hover': {
                                backgroundColor: 'white',
                                color: 'black'
                            },
                            fontFamily: 'Poppins',
                            fontWeight: parseFloat(amount) === 50 ? 600: 400,
                            fontSize: '16px',
                            borderRadius: '8px',
                            boxSizing: 'border-box',
                        }}>
                            $50
                        </Button>
                        <Button variant="contained" onClick={() => setAmount('100')} sx={{ 
                            bgcolor: parseFloat(amount) === 100 ? 'white' : '#191919', 
                            color: parseFloat(amount) === 100 ? 'black' : 'white',
                            width: {xs:'20%', sm:'23%'},
                            height: {xs:'42px', sm:'48px'},
                            '&:hover': {
                                backgroundColor: 'white',
                                color: 'black'
                            },
                            fontFamily: 'Poppins',
                            fontWeight: parseFloat(amount) === 100 ? 600: 400,
                            fontSize: '16px',
                            borderRadius: '8px',
                            boxSizing: 'border-box',
                        }}>
                            $100
                        </Button>
                        {/*<Button variant="contained" onClick={() => setAmount('200')} sx={{ 
                            bgcolor: parseFloat(amount) === 200 ? 'white' : '#191919', 
                            color: parseFloat(amount) === 200 ? 'black' : 'white',
                            width: {xs:'20%', sm:'23%'},
                            height: {xs:'42px', sm:'48px'},
                            '&:hover': {
                                backgroundColor: 'white',
                                color: 'black'
                            },
                            fontFamily: 'Poppins',
                            fontWeight: parseFloat(amount) === 200 ? 600: 400,
                            fontSize: '16px',
                            borderRadius: '8px',
                            boxSizing: 'border-box',
                        }}>
                            $200
                        </Button>*/}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', boxSizing: 'border-box', borderTop: '1px solid gray', mt: '12px' }}>
                        <PaymentInputs 
                            cardNumber={cardNumber} setCardNumber={setCardNumber} 
                            expiry={expiry} setExpiry={setExpiry}
                            cvc={cvc} setCVC={setCVC}
                        />
                    </Box>
                    <Button variant="contained" 
                        disabled={!isValidValue(parseFloat(amount)) || !isValidValue(cardNumber) || !isValidValue(expiry) || !isValidValue(cvc)}
                        onClick={() => handleSubmit()} 
                        sx={{ 
                            bgcolor: 'rgba(168, 0, 0, 0.8)', 
                            color: 'white',
                            width: '100%',
                            height: '48px',
                            '&:hover': {
                                backgroundColor: '#A80000',
                                boxShadow: '1px 1px 1px black !important',
                            },
                            '&.Mui-disabled': {
                                bgcolor: 'rgba(168, 0, 0, 0.8)',
                                color: 'white',
                                cursor: 'not-allowed'
                            },
                            fontFamily: 'Montserrat',
                            fontWeight: 600,
                            fontSize: '16px',
                            borderRadius: '8px',
                            boxSizing: 'border-box',
                            mb: '12px'
                        }}
                    >
                        {isValidValue(parseFloat(amount)) ? `DEPOSIT $${parseFloat(amount).toFixed(2)}` : 'ENTER VALID AMOUNT'}
                    </Button>
                </DialogContent>
            </Dialog>
        </ThemeProvider>
    )
}

export default CreditCardDialog