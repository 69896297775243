import { useState, useEffect, useLayoutEffect } from 'react';
import { useNavigate } from "react-router-dom";

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import PasswordTextField from "../components/PasswordTextField";

import { isUserInEADB, isUserInAPDB, register, login } from '../APIs';

import { supabase } from '../auth/client';

import { RiTwitterLine, RiTwitchFill } from "react-icons/ri";
import { IoLogoDiscord } from "react-icons/io5";


function LoginPage(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState({});
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  async function signInWithTwitch() {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'twitch',
      options: {
        redirectTo: `https://picks.esportsagent.gg/login`,
      },
    })
  }

  async function signInWithDiscord() {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'discord',
      options: {
        redirectTo: `https://picks.esportsagent.gg/login`,
      },
    })
  }

  async function signInWithTwitter() {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'twitter',
      options: {
        redirectTo: `https://picks.esportsagent.gg/login`,
      },
    })
  }

  const handleLogin = async (event) =>{
    event.preventDefault();
    setIsLoggingIn(true);

    // Pre-server-side validation:
    if (!email) {
      setErrorMsg({type: "email", msg: "An email is required for login"})
      setIsLoggingIn(false);
      return
    } else if (!password) {
      setErrorMsg({type: "password", msg: "A password is required for login"})
      setIsLoggingIn(false);
      return
    }
    
    // User connection between EsportAgent (EA) and AgentPicks (AP)
    const userData = {
      email: email,
      password: password
    }

    try {
      const res = await isUserInEADB(userData)

      // if user exists in EA DB
      if (res?.id && res?.email && res?.token) {

        let eaID = res?.id
        let ip = res?.ip

        // checks if user (based on email) is in AP DB
        const res3 = await isUserInAPDB({ email: email })
        if (res3.status === 403) {
          setErrorMsg({type: "other", msg: "Account Banned"})
          setIsLoggingIn(false);
          return
        } else if (res3.status !== 200) {
          setErrorMsg({type: "other", msg: "Database Lookup Error"})
          setIsLoggingIn(false);
          return
        }
        const APUser = res3.exists

        // if user is not in AP DB
        if (!APUser) {

          const fullUserData = {
            email: email,
            password: password,
            eaID: eaID,
            ip: ip
          }

          // TODO: prevent people from spamming /register?
          const res4 = await register(fullUserData)

          if (res4.status !== 200) {
            setErrorMsg({type: "other", msg: res4?.errorObj?.msg})
            setIsLoggingIn(false);
            return
          }

        }

        // runs if/when user is registered for agentpicks
        const res5 = await login(userData)

        if (res5.status !== 200) {
          setErrorMsg({type: "other", msg: res5?.errorObj?.msg})
          setIsLoggingIn(false);
          return
        }

        const { data, error } = await supabase.auth.setSession(res5.data)

        // if the user is not verified
        if (!res5?.isVerified) {
          props.setSession(res5.data)
          props.setIsVerified(res5?.isVerified)
          navigate('/settings', { state: { from: '/login' } });
          return
        } else {
          props.setSession(res5.data)
          props.setIsVerified(res5?.isVerified)
          navigate('/board')
          return
        }
      } 
      // if email or password is incorrect or user non existant in EA DB
      else {
        setErrorMsg({type: "other", msg: "Email or Password is incorrect"})
        return
      }
    } catch (error) {
      setErrorMsg({ type: "other", msg: "An error occurred during login" })
      return
    } finally {
      setIsLoggingIn(false);
    }
  }

  useLayoutEffect(() => {
    if (props.session && !isLoggingIn && !props.linkedLogin) {
      navigate('/');
    }
  }, [props.session, isLoggingIn]);

  useEffect(() => {
    if (Object.keys(errorMsg).length > 0 && errorMsg.type === "other") {
      const timer = setTimeout(() => {
        setErrorMsg({})
      }, 5000)

      return () => clearTimeout(timer);
    }
  }, [errorMsg])

  return (
    <div className='login-page'>

      {errorMsg && errorMsg.msg && errorMsg.type === "other" ? 
        <Alert sx={{ 
          position: 'fixed', top: '72px', width: {xs:'80%', md:'auto'}, 
          zIndex: 99, bgcolor: '#191919', 
          border: '2px solid #d32f2f', color: 'white', 
          fontFamily: 'Poppins', fontSize: {xs:'14px', sm:'16px'}, fontWeight: 500,
          alignSelf: 'center', lineHeight: 1.2, alignItems: 'center'
        }} severity="error" >
          {errorMsg?.msg}
        </Alert> 
      : null }

      {typeof(props.session) == 'undefined' || props.linkedLogin ?
        null
      :
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', maxWidth: '100%', margin: '25px 10px', }}>
            <Typography sx={{ color: 'white', fontFamily: 'Montserrat', fontWeight: 800, fontSize: {xs:'42px', sm:'48px'} }}>
              AGENTPICKS
            </Typography>
          </Box>
          <form onSubmit={handleLogin} style={{ width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <TextField
                type="email"
                id="email"
                label="Email"
                value={email}
                onChange={handleEmailChange}
                sx={{ margin: "10px", maxWidth: "350px", width: "95%",
                  input: { color: 'white' },
                  "& .MuiOutlinedInput-root": {
                    '& fieldset': {
                      borderColor: 'white',
                    },
                    '&:hover fieldset': {
                      borderColor: 'white',
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white",
                    }
                  } 
                }}
                InputLabelProps={{className: "textfield__color"}}
                error={errorMsg.type === "email" ? true : false}
                helperText={errorMsg.type === "email" ? errorMsg.msg : false}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: '12px' }}>
              <PasswordTextField 
                id="password"
                label="Password"
                value={password}
                onChange={handlePasswordChange}
                sx={{ margin: "10px", maxWidth: "350px", width: "95%", 
                  input: { color: 'white' },
                  "& .MuiOutlinedInput-root": {
                    '& fieldset': {
                      borderColor: 'white',
                    },
                    '&:hover fieldset': {
                      borderColor: 'white',
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white",
                    }
                  } 
                }}
                InputLabelProps={{className: "textfield__color"}}
                error={errorMsg.type === "password" ? true : false}
                helperText={errorMsg.type === "password" ? errorMsg.msg : false}
              />
            </Box>
            <div className='login-buttons'>
              <Button variant="contained" type='submit' sx={{ 
                bgcolor: 'rgba(168, 0, 0, 0.8)', 
                color: 'white',
                width: '140px',
                height: '48px',
                '&:hover': {
                    backgroundColor: '#A80000',
                },  
                fontFamily: 'Poppins',
                fontWeight: 500,
                fontSize: '16px',
                borderRadius: '8px',
                boxSizing: 'border-box',
                lineHeight: 1,
                textTransform: 'none',
                mr: {xs:'16px', sm:'30px'}
              }}>
                Log In
              </Button>
              <a style={{ fontWeight: "normal", fontFamily: 'Poppins', color: 'white', cursor: 'pointer', lineHeight: 1.1, }} onClick={() => window.open("https://esportsagent.gg/restore-password", "_blank")}>Forgot Password?</a>
            </div>
            <div className='login-buttons' style={{ marginTop: '16px', boxSizing: 'border-box' }}>
              <Typography sx={{ fontFamily: 'Poppins', color: 'white', lineHeight: 1.1, mr: {xs:'12px', sm:'20px'}, fontSize: {xs:'13px', sm:'16px'} }}>Don't have an account?</Typography>
              <Typography sx={{ fontFamily: 'Poppins', color: '#A80000', cursor: 'pointer', lineHeight: 1.1, fontSize: {xs:'13px', sm:'16px'}, '&:hover': { textDecoration: 'underline' } }} onClick={() => window.open("https://esportsagent.gg/signup", "_blank")}>Sign up here.</Typography>
            </div>
            <div className='login-buttons' style={{ marginTop: '18px', boxSizing: 'border-box', flexDirection: 'column', gap: '8px' }}>
              <Typography sx={{ fontFamily: 'Poppins', color: 'white', lineHeight: 1.1, fontSize: {xs:'13px', sm:'15px'}, borderTop: '2px solid rgba(255, 255, 255, 0.08)', pt: '18px' }}>Signed up for EsportsAgent with one of these?</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', mt: '12px' }}>
                <Button variant="contained" sx={{ 
                    bgcolor: 'rgba(255, 255, 255, 0.1)', 
                    color: 'white',
                    width: '240px',
                    height: '36px',
                    '&:hover': {
                        backgroundColor: '#303030',
                    },  
                    fontFamily: 'Poppins',
                    fontWeight: 400,
                    fontSize: '14px',
                    borderRadius: '8px',
                    boxSizing: 'border-box',
                    lineHeight: 1, 
                    textTransform: 'none',
                  }} 
                  onClick={() => signInWithTwitter()}
                >
                  <RiTwitterLine style={{ marginRight: '8px', width:'20px', height: '20px' }}/> Log In with Twitter
                </Button>
                <Button variant="contained" sx={{ 
                    bgcolor: 'rgba(255, 255, 255, 0.1)', 
                    color: 'white',
                    width: '240px',
                    height: '36px',
                    '&:hover': {
                        backgroundColor: '#303030',
                    },  
                    fontFamily: 'Poppins',
                    fontWeight: 400,
                    fontSize: '14px',
                    borderRadius: '8px',
                    boxSizing: 'border-box',
                    lineHeight: 1, 
                    textTransform: 'none',
                  }} 
                  onClick={() => signInWithDiscord()}
                >
                  <IoLogoDiscord style={{ marginRight: '8px', width:'20px', height: '20px' }}/> Log In with Discord
                </Button>
                <Button variant="contained" sx={{ 
                    bgcolor: 'rgba(255, 255, 255, 0.1)', 
                    color: 'white',
                    width: '240px',
                    height: '36px',
                    '&:hover': {
                        backgroundColor: '#303030',
                    },  
                    fontFamily: 'Poppins',
                    fontWeight: 400,
                    fontSize: '14px',
                    borderRadius: '8px',
                    boxSizing: 'border-box',
                    lineHeight: 1, 
                    textTransform: 'none',
                  }} 
                  onClick={() => signInWithTwitch()}
                >
                  <RiTwitchFill style={{ marginRight: '8px', width:'20px', height: '20px' }}/> Log In with Twitch
                </Button>
              </Box>
            </div>
          </form>
        </>
      }

    </div>
  );
}

export default LoginPage;
