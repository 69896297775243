import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Alert from '@mui/material/Alert';
import Pagination from '@mui/material/Pagination';

import { supabase } from '../auth/client';

import { getEntries, getUserInfo } from '../APIs';

import { IoMdSettings } from "react-icons/io";
import EntrySummary from "../components/EntrySummary";
import CreditCardDialog from "../components/CreditCardDialog";
import MyEntriesSkeleton from "../skeletons/MyEntriesSkeleton";
import WithdrawDialog from "../components/WithdrawDialog";

function EntriesPage(props) {

  const navigate = useNavigate()

  const [open2, setOpen2] = useState(false)

  const [entries, setEntries] = useState(null)
  const [type, setType] = useState("OPEN")
  const [walletBalance, setWalletBalance] = useState(0)
  const [entriesWon, setEntriesWon] = useState(0)
  const [amountWon, setAmountWon] = useState(0)
  const [joinDate, setJoinDate] = useState("")
  const [name, setName] = useState("")

  const [currentPage, setCurrentPage] = useState(1)
  const ENTRIES_PER_PAGE = 5

  // Get entries for selected page
  const entriesSelected = entries?.[type.toLowerCase()] || []
  // Calculate total pages
  const totalPages = entriesSelected.length >= 1 ? Math.ceil(entriesSelected.length / ENTRIES_PER_PAGE) : 0
  // Get current page entries
  const currentEntries = entriesSelected.slice(
    (currentPage - 1) * ENTRIES_PER_PAGE,
    currentPage * ENTRIES_PER_PAGE
  )

  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  const [errorMsg, setErrorMsg] = useState({})
  const [success, setSuccess] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const fetchInfo = async () => {
    setIsLoading(true)
    const response = await getUserInfo({token: props.session.access_token})
    
    if (response.status === 401) {
      props.setSession(null)
      const { error } = await supabase.auth.signOut()
      navigate('/login')
      return
    } else if (response.status !== 200) {
      setIsLoading(false)
      return
    }

    setWalletBalance(parseFloat(response.info.walletBalance.$numberDecimal))
    props.setPromoBalance(parseFloat(response.info.promoBalance.$numberDecimal))
    setEntriesWon(response.info.entriesWon)
    setAmountWon(parseFloat(response.info.amountWon.$numberDecimal))
    setJoinDate(response.info.joinDate)
    setName(response.info.name)

    await fetchEntries()

    setIsLoading(false)

    return
  }

  // gets a user's entries (from users DB)
  const fetchEntries = async () => {
    const response = await getEntries({token: props.session.access_token})
    
    if (response.status === 401) {
      props.setSession(null)
      const { error } = await supabase.auth.signOut()
      navigate('/login')
      return
    } else if (response.status !== 200) {
      setIsLoading(false)
      return
    }

    setEntries(response.entries)

    return
  }

  const handleDepositClick = async () => {
    if (name && props.isVerified) {
      props.setOpen(props.isVerified)
    } else {
      setErrorMsg({type: 'unverified', msg: 'ID verification required for deposit'})
    }
    return
  }

  const handleWithdrawClick = async () => {
    if (name && props.isVerified) {
      setOpen2(props.isVerified)
    } else {
      setErrorMsg({type: 'unverified', msg: 'ID verification required for withdraw'})
    }
    return
  }
  

  useEffect(() => {
    if (props.session) {
      fetchInfo()
    }
  }, [props.session])

  useEffect(() => {
    if (props.session) {
      fetchEntries()
    }
  }, [props.session, type])

  useEffect(() => {
    if (success) {
      fetchInfo()
      const timer = setTimeout(() => {
        setSuccess(0)
      }, 5000)

      return () => clearTimeout(timer);
    }
  }, [success])

  useEffect(() => {
    if (Object.keys(errorMsg).length > 0 && errorMsg.type === "unverified") {
      const timer = setTimeout(() => {
        setErrorMsg({})
      }, 5000)

      return () => clearTimeout(timer);
    }
  }, [errorMsg])


  return (
    <>
      {isLoading ?
        <MyEntriesSkeleton type={type} setType={setType} />
      :
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: {xs: 'column', lg: 'row'}, gap: '16px', mt: '36px', pb: '130px', alignItems: {xs:'center', lg:'flex-start'}, boxSizing: 'border-box' }}>
          {success === 1 ? 
            <Alert sx={{ 
              position: 'fixed', top: '72px', width: {xs:'80%', md:'auto'}, 
              zIndex: 99, bgcolor: '#191919', 
              border: '2px solid #2e7d32', color: 'white', 
              fontFamily: 'Poppins', fontSize: {xs:'14px', sm:'16px'}, fontWeight: 500,
              alignSelf: 'center', lineHeight: 1.2, alignItems: 'center'
            }} severity="success" >
              Deposit Successful
            </Alert> 
          : null }
          {success === -1 ? 
            <Alert sx={{ 
              position: 'fixed', top: '72px', width: {xs:'80%', md:'auto'}, 
              zIndex: 99, bgcolor: '#191919', 
              border: '2px solid #d32f2f', color: 'white', 
              fontFamily: 'Poppins', fontSize: {xs:'14px', sm:'16px'}, fontWeight: 500,
              alignSelf: 'center', lineHeight: 1.2, alignItems: 'center'
            }} severity="error" >
              Deposit Unsuccessful: Payment Declined
            </Alert> 
          : null }
          {errorMsg && errorMsg.msg && errorMsg.type === 'unverified' ? 
            <Alert sx={{ 
                position: 'fixed', top: '72px', width: {xs:'80%', md:'auto'}, 
                zIndex: 99, bgcolor: '#191919', 
                border: '2px solid #d32f2f', color: 'white', 
                fontFamily: 'Poppins', fontSize: {xs:'14px', sm:'16px'}, fontWeight: 500,
                alignSelf: 'center', lineHeight: 1.2, alignItems: 'center'
            }} severity="error" >
                {errorMsg?.msg}
            </Alert> 
          : null }
          {props.open && name && props.isVerified ? <CreditCardDialog open={props.open} setOpen={props.setOpen} session={props.session} setSession={props.setSession} setSuccess={setSuccess} /> : null}
          {open2 && name && props.isVerified ? <WithdrawDialog open={open2} setOpen={setOpen2} session={props.session} setSession={props.setSession} /> : null}
          <Box sx={{ display: 'flex', flexDirection: 'column', width: {xs: '90%', lg:'366px'} }}>
            <Box sx={{  
              border: '1px solid rgba(255, 255, 255, 0.2)', 
              boxsizing: 'border-box', 
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'center', 
              borderRadius: '8px', 
              p: '16px',
              position: 'relative'
            }}>
              <Box sx={{ position: 'absolute', top: '6px', right: '6px', cursor: 'pointer', color:"#808080", '&:hover': {color: 'lightgray'} }} >
                <IoMdSettings size={28} onClick={() => navigate('/settings')} />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '8px', boxsizing: 'border-box', mt: '16px' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: {xs: '20px', sm:'24px'}, color: name ? 'white' : '#A80000', lineHeight: 1.1, textAlign: 'left'  }} >
                  {name && props.isVerified ? 
                    name
                  : 
                    "ID Verification Required"
                  }
                </Typography>
                {name && props.isVerified ? 
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: {xs: '14px', sm: '16px' }, color: 'white', lineHeight: 1.1 }} >
                    Joined {joinDate}
                  </Typography>
                :
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: {xs: '14px', sm: '16px' }, color: 'white', lineHeight: 1.1 }} >
                    Verify your ID {<span onClick={() => navigate('/settings')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>here</span>} to place entries
                  </Typography>
                }
              </Box>
              <Box sx={{ display: 'flex', bgcolor: '#191919', borderRadius: '8px', height: '80px', flexDirection: 'row', p: '8px', justifyContent: 'space-around', boxsizing: 'border-box', mt: {xs: '20px', lg:'40px'} }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: {xs: '24px', sm:'32px'}, color: 'white' }} >
                    {entriesWon}
                  </Typography>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: {xs: '14px', sm:'16px'}, color: 'rgba(255, 255, 255, 0.5)' }} >
                    Entries Won
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: {xs: '24px', sm:'32px'}, color: 'white' }} >
                    ${amountWon.toFixed(2)}
                  </Typography>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: {xs: '14px', sm:'16px'}, color: 'rgba(255, 255, 255, 0.5)' }} >
                    Amount Won
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', boxsizing: 'border-box', gap: '16px', mt: '16px'}}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', bgcolor: '#191919', borderRadius: '8px', width: '100%', height: '80px' }}>
                  <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '16px', color: 'white' }} >
                    Balance:
                  </Typography>
                  <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '18px', color: 'white' }} >
                    ${walletBalance.toFixed(2)}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', bgcolor: '#191919', borderRadius: '8px', width: '100%', height: '80px' }}>
                  <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '16px', color: 'white' }} >
                    Promo:
                  </Typography>
                  <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '18px', color: 'white' }} >
                    ${props.promoBalance.toFixed(2)}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', boxsizing: 'border-box', gap: '16px', mt: '16px'}}>
                <Button onClick={() => handleWithdrawClick()} sx={{
                  display: 'flex',
                  border: 1,
                  borderColor: '#A80000',
                  bgcolor: 'rgba(168, 0, 0, 0.1)',
                  borderRadius: '8px 0px 0px 8px',
                  '&:hover': {
                    backgroundColor: 'rgba(168, 0, 0, 0.25)',
                  },
                  height: '60px',
                  width: '100%',
                  justifyContent: 'center', 
                  alignItems: 'center',
                  p: 0,
                  m: 0,
                  textTransform: 'none'
                }} >
                  <Typography sx={{
                    fontFamily: 'Montserrat',
                    fontWeight: 500,
                    fontSize: '16px',
                    color: 'white',
                  }}>
                    Withdraw
                  </Typography>
                </Button>
                <Button onClick={() => handleDepositClick()} sx={{
                  display: 'flex',
                  border: 1,
                  borderColor: '#1CC500',
                  bgcolor: 'rgba(28, 197, 0, 0.1)',
                  borderRadius: '0px 8px 8px 0px',
                  '&:hover': {
                    backgroundColor: 'rgba(28, 197, 0, 0.2)',
                  },
                  height: '60px',
                  width: '100%',
                  justifyContent: 'center', 
                  alignItems: 'center',
                  p: 0,
                  m: 0,
                  textTransform: 'none'
                }} >
                  <Typography sx={{
                    fontFamily: 'Montserrat',
                    fontWeight: 500,
                    fontSize: '16px',
                    color: 'white',
                  }}>
                    Deposit
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
          <Box sx={{ flexDirection: 'column', maxWidth: {xs: '92%', lg:'70%'}, boxsizing: 'border-box', px: {xs:0, lg:'32px'}, pt: '16px', mt: {xs: '8px', lg: 0} }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: {xs:'8px', sm:'16px'} }} >
              <Button onClick={() => {
                  setType("OPEN")
                  setCurrentPage(1)
                }}
                variant="contained" 
                sx={{ 
                  bgcolor: type === 'OPEN' ? 'rgba(168, 0, 0, 0.8)' : 'white',  
                  color: type === 'OPEN' ? 'white' : 'black',
                  width: '220px',
                  height: '44px',
                  '&:hover': {
                    backgroundColor: '#A80000',
                    color: 'white'
                  },
                  fontFamily: 'Poppins',
                  fontWeight: 500,
                  fontSize: {xs:'14px', sm:'16px'},
                  borderRadius: '8px',
                  lineHeight: 1
              }}>
                Open Entries
              </Button>
              <Button onClick={() => {
                  setType("PAST")
                  setCurrentPage(1)
                }}  
                variant="contained" 
                sx={{ 
                  bgcolor: type === 'PAST' ? 'rgba(168, 0, 0, 0.8)' : 'white', 
                  color: type === 'PAST' ? 'white' : 'black',
                  width: '220px',
                  height: '44px',
                  '&:hover': {
                    backgroundColor: '#A80000',
                    color: 'white'
                  },
                  fontFamily: 'Poppins',
                  fontWeight: 500,
                  fontSize: {xs:'14px', sm:'16px'},
                  borderRadius: '8px',
                  lineHeight: 1
              }}>
                Past Entries
              </Button>
              <Button onClick={() => {
                  setType("FREE")
                  setCurrentPage(1)
                }} 
                variant="contained" 
                sx={{ 
                  bgcolor: type === 'FREE' ? 'rgba(168, 0, 0, 0.8)' : 'white', 
                  color: type === 'FREE' ? 'white' : 'black',
                  width: '220px',
                  height: '44px',
                  '&:hover': {
                    backgroundColor: '#A80000',
                    color: 'white'
                  },
                  fontFamily: 'Poppins',
                  fontWeight: 500,
                  fontSize: {xs:'14px', sm:'16px'},
                  borderRadius: '8px',
                  lineHeight: 1
              }}>
                Free Entries
              </Button>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', mt: {xs: '32px', lg:'24px'}, gap: '16px' }}>
              {
                entriesSelected.length > 0 ?
                  currentEntries.map((bet, index) => (
                    <EntrySummary key={bet._id} index={index} bet={bet} session={props.session} setSession={props.setSession} type={type} currentPage={currentPage} />
                  ))
                :
                  <Box sx={{ minHeight: '300px', height: '300px', width: '100%' }}>
                    <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, color: 'white', fontSize: {xs:'22px', lg:'28px'}, mt: '64px' }} >NO ENTRIES FOUND</Typography>
                  </Box>
              }
            </Box>
            {totalPages > 1 ?
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: '20px' }}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  sx={{
                    '& .MuiPaginationItem-root': {
                      fontFamily: 'Poppins',
                      fontSize: {xs:'16px', sm:'18px'},
                      color: 'white', // Normal state
                      minWidth: {xs:'24px', sm:'32px'}

                    },
                    '& .MuiPaginationItem-root.Mui-selected': {
                      fontFamily: 'Poppins',
                      fontSize: {xs:'16px', sm:'18px'},
                      color: '#A80000', // Selected state
                      minWidth: {xs:'24px', sm:'32px'}
                    },
                    '& .MuiPaginationItem-root:hover': {
                      fontFamily: 'Poppins',
                      fontSize: {xs:'16px', sm:'18px'},
                      color: '#A80000', // Hover state
                      minWidth: {xs:'24px', sm:'32px'}
                    },
                  }}
                />
              </Box>
            :
              null
            }
          </Box>
        </Box>
      }
    </>
  )
}

export default EntriesPage