import { useState, useEffect } from 'react';

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';
import ProgressBar from './ProgressBar';
import Logo from './Logo';

import moment from 'moment-timezone';

function EntrySummaryCard(props) {

    const [win, setWin] = useState(0)

    const checkWin = () => {
        // if finished and actual and correctly bet over and not DNP: win
        if (props.pickObj.finished === true && props.pickObj.actual !== null && props.pickObj.actual > props.pickObj.predicted && props.pickObj.bet === "OVER" && props.pickObj.actual !== -1) {
            setWin(1)
        } 
        // if finished and actual and correctly bet under and not DNP: win
        else if (props.pickObj.finished === true && props.pickObj.actual !== null && props.pickObj.actual < props.pickObj.predicted && props.pickObj.bet === "UNDER" && props.pickObj.actual !== -1) {
            setWin(1)
        } 
        // if no actual, DNP, or push: no win
        else if (props.pickObj.actual === null || (props.pickObj.finished === true && props.pickObj.actual === -1) || (props.pickObj.finished === true && props.pickObj.actual === props.pickObj.predicted)) {
            setWin(0)
        } 
        // if finished: loss
        else if (props.pickObj.finished === true) {
            setWin(-1)
        }
    }

    const convertToDate = (dateString) => {

        const momentObj = moment(dateString);
        const localDate = moment(momentObj).local()
    
        // Get the individual components
        const weekday = localDate.format('ddd'); // Short weekday name (e.g., "Mon")
        const month = localDate.format('MMM');  // Short month name (e.g., "Jun")
        const day = localDate.date();           // Day of the month (1-31)
        const year = localDate.year();          // Full year (e.g., 2024)
    
        // Add a '.' after the month if it's not May
        let formattedMonth = month;
        if (month !== "May") {
            formattedMonth += ".";
        }
    
        // Format the date
        const matchDate = `${weekday}, ${formattedMonth} ${day} ${year}`;
    
        return matchDate;
    };

    const convertToTime = (dateString) => {

        const momentObj = moment(dateString);
        const localMoment = moment(momentObj).local();
    
        // Format the time
        const matchTime = localMoment.format('h:mm A');
    
        return matchTime;
    };

    useEffect(() => {
        checkWin()
    }, [])

    return (
        <Box sx={{ height: '150px', width: '100%', borderRadius: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', boxSizing: 'border-box', my: '8px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', pr: {xs:'6px', sm:'12px'}, height: '70%', width: {xs:'94px', sm:'132px'}, boxSizing: 'border-box' }}>
                <Logo teamColor={props.pickObj.img} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', width: '100%' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: '16px', color: 'white', lineHeight: 1.1, textAlign: 'left' }}>{props.pickObj.name}</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '5px', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', bgcolor: 'rgba(255, 255, 255, 0.05)', width: '37px', height: '100%', borderRadius: '4px', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                        <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 300, fontSize: '10px', color: 'lightgray', mb: '-1px' }}>{props.pickObj.game}</Typography>
                    </Box>
                    <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: '12px', color: 'white' }}>{props.pickObj.team}</Typography>
                </Box>
                <Box sx={{ mt: {xs:'2px', sm:'5px'}, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', width: '100%', gap: '2px' }}>
                    <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: {xs:'11px', sm:'12px'}, color: 'white', lineHeight: {xs: 1.1, sm: 1.5}, textAlign: 'left', mr: '4px' }}>{convertToDate(props.pickObj.matchDateTimeEST)} at {convertToTime(props.pickObj.matchDateTimeEST)}</Typography>
                    <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: {xs:'11px', sm:'12px'}, color: 'white' }}>vs {props.pickObj.opp}</Typography>
                </Box>
                <Box sx={{ mt: {xs:'2px', sm:'5px'}, display: 'flex', flexDirection: 'row', justifyContent: {xs:'flex-start', sm:'center'}, width: '100%', alignItems: 'center', gap: '5px' }}>
                    <ProgressBar actual={props.pickObj.actual} predicted={props.pickObj.predicted} win={win} expired={props.pickObj.expired} finished={props.pickObj.finished} />
                </Box>
            </Box>
            {props.pickObj.actual !== null && props.pickObj.actual === -1 ?
                <Box sx={{ display: 'flex', justifyContent: 'center', boxSizing: 'border-box', alignItems: 'center'  }}>
                    <Box sx={{ height: {xs:'100px', sm:'120px'}, width: {xs:'100px', sm:'120px'}, bgcolor: 'rgba(255, 255, 255, 0.05)', borderRadius: '16px', display: 'flex', flexDirection: 'column', justifyContent: 'center', boxSizing: 'border-box' }}>
                        <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: {xs:'20px', sm:'28px'}, color: "white" }}>DNP</Typography>
                    </Box>
                </Box>
            :
                <Box sx={{ display: 'flex', justifyContent: 'center', boxSizing: 'border-box', alignItems: 'center'  }}>
                    <Box sx={{ height: {xs:'84px', sm:'120px'}, width: {xs:'84px', sm:'120px'}, bgcolor: 'rgba(255, 255, 255, 0.05)', borderRadius: '16px', display: 'flex', flexDirection: 'column', justifyContent: 'center', boxSizing: 'border-box' }}>
                        <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: {xs:'11px', sm:'12px'}, lineHeight: {xs: 1.2, sm: 1.5}, color: 'white' }}>{props.pickObj.bet}</Typography>
                        {props.pickObj.stat === "DMG" ? 
                            <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: {xs: props.pickObj.predicted.toString().length > 4 ? '20px' : '22px', sm: props.pickObj.predicted.toString().length > 4 ? '30px' : '32px'}, lineHeight: {xs: 1.2, sm: 1.5}, color: win === 1 ? '#1CC500' : win === 0 ? "white" : "#A80000" }}>{props.pickObj.predicted}</Typography>
                        :
                            <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: {xs: props.pickObj.predicted.toString().length > 4 ? '20px' : '22px', sm: props.pickObj.predicted.toString().length > 4 ? '30px' : '32px'}, lineHeight: {xs: 1.2, sm: 1.5}, color: win === 1 ? '#1CC500' : win === 0 ? "white" : "#A80000" }}>{props.pickObj.predicted.toString().includes('.') ? props.pickObj.predicted : `${props.pickObj.predicted}.0`}</Typography>
                        }
                        <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: {xs:'11px', sm:'12px'}, lineHeight: {xs: 1.0, sm: 1.5}, color: 'white' }}>{props.pickObj.stat}</Typography>
                        <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: {xs:'11px', sm:'12px'}, lineHeight: {xs: 1.2, sm: 1.5}, color: 'white' }}>MAP{props.pickObj.maps.length > 1 ? "S" : ""} {props.pickObj.maps}</Typography>
                    </Box>

                </Box>
            }
        </Box>
    );
}
    
export default EntrySummaryCard;