import { Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

function SettingsSkeleton() {
  return (
    <Box sx={{ width: {xs: '90%', sm:'70%', md: '50%'}, maxWidth: '600px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '16px', mt: '30px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', gap: '10px', boxSizing: 'border-box' }}>
        <Skeleton variant="rounded" width={140} height={20} sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)'}} />
        <Skeleton variant="rounded" width={'100%'} height={'46px'} sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', gap: '10px', boxSizing: 'border-box' }}>
        <Skeleton variant="rounded" width={140} height={20} sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)'}} />
        <Skeleton variant="rounded" width={'100%'} height={'46px'} sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', gap: '10px', boxSizing: 'border-box' }}>
        <Skeleton variant="rounded" width={140} height={20} sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)'}} />
        <Skeleton variant="rounded" width={'100%'} height={'46px'} sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', gap: '10px', boxSizing: 'border-box' }}>
        <Skeleton variant="rounded" width={140} height={20} sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)'}} />
        <Skeleton variant="rounded" width={'100%'} height={'46px'} sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', gap: '10px', boxSizing: 'border-box' }}>
        <Skeleton variant="rounded" width={140} height={20} sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)'}} />
        <Skeleton variant="rounded" width={'100%'} height={'46px'} sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', gap: '10px', boxSizing: 'border-box', mt: '8px' }}>
        <Skeleton variant="rounded" width={'70%'} height={28} sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)'}} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', gap: '10px', boxSizing: 'border-box' }}>
        <Skeleton variant="rounded" width={'70%'} height={28} sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)'}} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', gap: '10px', boxSizing: 'border-box', mt: '8px' }}>
        <Skeleton variant="rounded" width={'100%'} height={'46px'} sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
      </Box>
    </Box>
  )
}

export default SettingsSkeleton