import { supabase } from '../auth/client.js';

export const supabaseLogin = async (userData) => {
    try {
        const { data, error } = await supabase.auth.signInWithPassword({
            email: userData.email,
            password: userData.password,
        })
        if (error) throw error
        return data.session
    } catch (error) {
        return false
    }
}