
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

function GameBtn(props) {

    return (
        <>
            <Button
                variant='outlined'
                size='large'
                onClick={() => {
                    props.setGame(props.gameTitle)
                }}
                sx={{
                    width: {xs:'72px', sm: '90px'},
                    height: {xs:'72px', sm: '82px', md: '90px'}, 
                    maxHeight: '90px', 
                    minWidth: {xs:'72px', sm: '82px', lg: '90px'},
                    fontWeight: 'regular', 
                    fontFamily: 'Poppins',
                    color: (props.game === props.gameTitle) ? 'white' : 'gray', 
                    borderRadius: '15px',
                    border: 2,
                    borderColor: (props.game === props.gameTitle) ? '#A80000' : '#212529', 
                    display: 'flex', 
                    flexDirection: 'column',
                    bgcolor: (props.game === props.gameTitle) ? 'rgba(168, 0, 0, 0.05)' : 'rgba(255, 255, 255, 0.1)', 
                    letterSpacing: {xs: 1, sm: 1.2},
                    ':hover': {bgcolor: 'rgba(168, 0, 0, 0.05)', border: 2, borderColor: '#A80000' }
                }}
            >
                {props.gameTitle === "CS2" ? 
                    <Box sx={{ mb: '-8px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: {xs:'64px', sm:'70px'}, height: {xs:'40px', sm:'50px'} }} >
                        <img src={props.gameImgWhite} width={'100%'} height={'56px'}/>
                    </Box>
                :
                    props.gameTitle === "RL" || props.gameTitle === "APEX" || props.gameTitle === "R6" ? 
                        <Box sx={{ mb: '-8px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: {xs:'40px', sm:'44px'}, height: {xs:'40px', sm:'50px'} }} >
                            <img src={props.gameImgWhite} width={'100%'} height={'44px'}/>
                        </Box>
                    :
                        props.gameTitle === "LOL" || props.gameTitle === "VAL" ? 
                            <Box sx={{ mb: '-8px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: {xs:'36px', sm:'40px'}, height: {xs:'40px', sm:'50px'} }} >
                                <img src={props.gameImgWhite} width={'100%'} height={'40px'}/>
                            </Box>
                        :
                            <Box sx={{ mb: '-8px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: props.gameTitle == "HALO" ? {xs:'54px', sm:'60px'} : {xs:'48px', sm:'54px'}, height: {xs:'40px', sm:'50px'} }} >
                                <img src={props.gameImgWhite} width={'100%'} height={'56px'}/>
                            </Box>
                    }
                {props.gameTitle}
            </Button>
        </>
    );
}
    
export default GameBtn;