import { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';

import { IoMdClose } from "react-icons/io";
import Logo from './Logo';

import moment from 'moment-timezone';

function CartCard(props) {

    const [higherSelected, setHigherSelected] = useState(false)
    const [lowerSelected, setLowerSelected] = useState(false)

    const removePick = () => {
        const updatedPicks = props.selectedPicks.filter(pick => pick._id !== props.pickObj._id)
        props.setSelectedPicks(updatedPicks)
    }

    const handlePick = (choice) => {
        if (props.selectedPicks.length === 0) {
            props.setCartOpen(true)
        }
        // if clicking an already selected button or modifying an already selected pick
        if (higherSelected || lowerSelected || props.selectedPicks.some(pick => pick._id === props.pickObj._id)) {
            // if clicking an already selected button
            if ((higherSelected && choice === "OVER") || lowerSelected && (choice === "UNDER")) {
                const updatedPicks = props.selectedPicks.filter(pick => pick._id !== props.pickObj._id)
                props.setSelectedPicks(updatedPicks)
            } 
            // if changing from over to under or under to over
            else {
                const updatedPicks = props.selectedPicks.map(pick => {
                    if (pick._id === props.pickObj._id) {
                        return {
                            ...pick,
                            bet: choice,
                        }
                    } else {
                        return pick
                    }
                })
                props.setSelectedPicks(updatedPicks)
            }
        } 
        // if selecting pick for a the first time
        else {
            props.setSelectedPicks([...props.selectedPicks, {
                _id: props.pickObj._id,  
                name: props.pickObj.name,
                game: props.pickObj.game,
                team: props.pickObj.team,
                opp: props.pickObj.opp,
                pro: props.pickObj.pro,
                img: props.pickObj.img,
                matchDateTimeEST: props.pickObj.matchDateTimeEST,
                maps: props.pickObj.maps,
                predicted: props.pickObj.predicted,
                special: props.pickObj?.special ? props.pickObj?.special : "",
                stat: props.pickObj.stat,
                bet: choice,
            }
        ])
        }
    }

    const convertToDate = (dateString) => {

        const momentObj = moment(dateString);
        const localDate = moment(momentObj).local()
    
        // Get the individual components
        const weekday = localDate.format('ddd'); // Short weekday name (e.g., "Mon")
        const month = localDate.format('MMM');  // Short month name (e.g., "Jun")
        const day = localDate.date();           // Day of the month (1-31)
        const year = localDate.year();          // Full year (e.g., 2024)
    
        // Add a '.' after the month if it's not May
        let formattedMonth = month;
        if (month !== "May") {
            formattedMonth += ".";
        }
    
        // Format the date
        const matchDate = `${weekday}, ${formattedMonth} ${day} ${year}`;
    
        return matchDate;
    };

    const convertToTime = (dateString) => {

        const momentObj = moment(dateString);
        const localMoment = moment(momentObj).local();
    
        // Format the time
        const matchTime = localMoment.format('h:mm A');
    
        return matchTime;
    };

    const checkIfSelected = () => {
        if (!props.selectedPicks) {
            return false
        }
        setHigherSelected(props.selectedPicks.some(pick => pick._id === props.pickObj._id && pick.bet === "OVER"))
        setLowerSelected(props.selectedPicks.some(pick => pick._id === props.pickObj._id && pick.bet === "UNDER"))
    }

    useEffect(() => {
        checkIfSelected()
    }, [props.selectedPicks])

    return (
        <Box sx={{ height: '150px', width: '100%', bgcolor: 'rgba(255, 255, 255, 0.05)', border: '2px solid #A80000', borderRadius: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', boxSizing: 'border-box' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', px: '10px', pt: {xs: '12px', md: '0'}, height: '70%', width: '164px', maxWidth: {xs:'72px', md:'164px'}, boxSizing: 'border-box' }}>
                <Logo teamColor={props.pickObj.img} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', width: '100%' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: '16px', color: 'white', lineHeight: 1.1, mb: '3px', textAlign: 'left'  }}>{props.pickObj.name}</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '5px', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', bgcolor: 'rgba(255, 255, 255, 0.05)', width: '37px', height: '100%', borderRadius: '4px', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                        <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 300, fontSize: '10px', color: 'lightgray', mb: '-1px' }}>{props.pickObj.game}</Typography>
                    </Box>
                    <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: '12px', color: 'white' }}>{props.pickObj.team}</Typography>
                </Box>
                <Box sx={{ mt: '10px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', width: '100%' }}>
                    <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '12px', color: 'white', lineHeight: 1.2, textAlign: 'left' }}>{convertToDate(props.pickObj.matchDateTimeEST).slice(0, -5)} at {convertToTime(props.pickObj.matchDateTimeEST)}</Typography>
                    <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '12px', color: 'white', lineHeight: 1.2 }}>vs {props.pickObj.opp} MAP{props.pickObj.maps.length > 1 ? "S" : ""} {props.pickObj.maps}</Typography>
                </Box>
                <Box sx={{ mt: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', alignItems: 'center', gap: '5px' }}>
                    <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '20px', color: props.pickObj?.special === "DISCOUNTED" ? 'gold' : 'white', lineHeight: 1.1 }}>{props.pickObj.predicted}</Typography>
                    <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '12px', color: 'white', lineHeight: 1.1 }}>{props.pickObj.stat}</Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', height: '100%', justifyContent: 'space-between', flexDirection: 'column', alignItems: 'flex-end' }}>
                <Button onClick={() => removePick()} sx={{
                    minWidth: 0,
                    minHeight: 0,
                    p: 0,
                    m: '5px',
                    borderRadius: 100,
                    color: 'rgba(255, 255, 255, 0.2)',
                    '&:hover': {
                        backgroundColor: 'transparent',
                        color: 'rgba(255, 255, 255, 0.5)'
                    },
                }}>
                    <IoMdClose size={30}/>
                </Button>
                <Box sx={{ display: 'flex', flexDirection: 'column', m: '12px', gap: '2px' }}>
                    <Button onClick={() => handlePick('OVER')} sx={{
                        width: '64px',
                        height: '32px',
                        borderRadius: '8px 8px 0 0',
                        border: 1,
                        borderColor: 'white',
                        bgcolor: props.selectedPicks.some(pick => pick._id === props.pickObj._id && pick.bet === "OVER") ? '#A80000' : 'rgba(255, 255, 255, 0.1)',
                        fontFamily: 'Montserrat',
                        fontWeight: 400,
                        fontSize: '12px',
                        color: 'white',
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: '#A80000',
                        },
                    }} >
                        OVER
                    </Button>
                    <Button disabled={!props.pickObj.pro} onClick={() => handlePick('UNDER')} sx={{
                        visibility: props.pickObj.pro ? 'visible' : 'hidden',
                        width: '64px',
                        height: '32px',
                        borderRadius: '0 0 8px 8px',
                        border: 1,
                        borderColor: 'white',
                        bgcolor: props.selectedPicks.some(pick => pick._id === props.pickObj._id && pick.bet === "UNDER") ? '#A80000' : 'rgba(255, 255, 255, 0.1)',
                        fontFamily: 'Montserrat',
                        fontWeight: 400,
                        fontSize: '12px',
                        color: 'white',
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: '#A80000',
                        },
                    }} >
                        UNDER
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
    
export default CartCard;