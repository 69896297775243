import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";

function MyEntriesSkeleton(props) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: {xs: 'column', lg: 'row'}, gap: '16px', mt: '36px', mb: '130px', alignItems: {xs:'center', lg:'flex-start'}, boxSizing: 'border-box' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: {xs: '90%', lg:'366px'} }}>
            <Skeleton variant="rounded" sx={{ 
                bgcolor: 'rgba(255, 255, 255, 0.1)', 
                height: {xs:'374px', lg:'444px'}, 
                boxsizing: 'border-box',
            }} />
        </Box>
        <Box sx={{ display: 'flex', width: {xs: '92%', lg:'756px'}, flexDirection: 'column', height: '100%', gap: '16px', px: {xs:'0px', md:'32px'}, boxSizing: 'border-box', alignItems: 'center', mt: {xs:'24px', lg:'16px'} }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: {xs:'8px', sm:'16px'}, width: '100%' }} >
              <Button onClick={() => props.setType("OPEN")} variant="contained" sx={{ 
                  bgcolor: props.type === 'OPEN' ? 'rgba(168, 0, 0, 0.8)' : 'white',  
                  color: props.type === 'OPEN' ? 'white' : 'black',
                  width: '220px',
                  height: '44px',
                  '&:hover': {
                    backgroundColor: '#A80000',
                    color: 'white'
                  },
                  fontFamily: 'Poppins',
                  fontWeight: 500,
                  fontSize: {xs:'14px', sm:'16px'},
                  borderRadius: '8px',
                  lineHeight: 1
              }}>
                Open Entries
              </Button>
              <Button onClick={() => props.setType("PAST")} variant="contained" sx={{ 
                  bgcolor: props.type === 'PAST' ? 'rgba(168, 0, 0, 0.8)' : 'white', 
                  color: props.type === 'PAST' ? 'white' : 'black',
                  width: '220px',
                  height: '44px',
                  '&:hover': {
                    backgroundColor: '#A80000',
                    color: 'white'
                  },
                  fontFamily: 'Poppins',
                  fontWeight: 500,
                  fontSize: {xs:'14px', sm:'16px'},
                  borderRadius: '8px',
                  lineHeight: 1
              }}>
                Past Entries
              </Button>
              <Button onClick={() => props.setType("FREE")} variant="contained" sx={{ 
                  bgcolor: props.type === 'FREE' ? 'rgba(168, 0, 0, 0.8)' : 'white', 
                  color: props.type === 'FREE' ? 'white' : 'black',
                  width: '220px',
                  height: '44px',
                  '&:hover': {
                    backgroundColor: '#A80000',
                    color: 'white'
                  },
                  fontFamily: 'Poppins',
                  fontWeight: 500,
                  fontSize: {xs:'14px', sm:'16px'},
                  borderRadius: '8px',
                  lineHeight: 1
              }}>
                Free Entries
              </Button>
            </Box>
            <Skeleton variant="rounded" sx={{ 
                bgcolor: 'rgba(255, 255, 255, 0.1)',
                width: {xs: '100%', md: '692px'},
                height: '180px'
            }} />
            <Skeleton variant="rounded" sx={{ 
                bgcolor: 'rgba(255, 255, 255, 0.1)',
                width: {xs: '100%', md: '692px'},
                height: '180px'
            }} />
            <Skeleton variant="rounded" sx={{ 
                bgcolor: 'rgba(255, 255, 255, 0.1)',
                width: {xs: '100%', md: '692px'},
                height: '180px'
            }} />
            <Skeleton variant="rounded" sx={{ 
                bgcolor: 'rgba(255, 255, 255, 0.1)',
                width: {xs: '100%', md: '692px'},
                height: '180px'
            }} />
        </Box>
    </Box>
  )
}
export default MyEntriesSkeleton