import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { DataGrid } from '@mui/x-data-grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { adminGetEntries } from '../APIs';

import { supabase } from '../auth/client';

function AdminEntriesDashboard(props) {

    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
        },
    });

    const [entriesData, setEntriesData] = useState(null);

    // used for skeleton components when fetching data from backend
    const [isLoading, setIsLoading] = useState(true);

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    });

    const fetchEntriesData = async () => {

        setIsLoading(true)

        const userData = {
            token: props.adminSession.access_token
        }

        const data = await adminGetEntries(userData)
        if (data.status === 401) {
            props.setAdminSession(null)
            const { error } = await supabase.auth.signOut()
            return
        } else if (data.status !== 200) {
          return
        }
        setEntriesData(data.entries)
        setIsLoading(false)
        return
    }

    useEffect(() => {
        fetchEntriesData()
    }, [])


    const rows = entriesData

    // no referredBy, lastUpdated, CCboostamount, Mboostamount, Pboostamount
    const columns = [
        {   
            field: '_id', 
            headerName: 'id', 
            width: 100,
        },
        {
            field: 'userID',
            headerName: 'userID',
            width: 100,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'email',
            width: 200,
            editable: false,
        },
        {
            field: 'betAmount',
            headerName: 'betAmount',
            width: 100,
            type: 'number',
            editable: false,
        },
        {
            field: 'totalWinAmount',
            headerName: 'winAmount',
            width: 100,
            type: 'number',
            editable: false,
        },
        {
            field: 'wins',
            headerName: 'wins',
            width: 60,
            type: 'number',
            editable: false,
        },
        {
            field: 'losses',
            headerName: 'losses',
            width: 60,
            type: 'number',
            editable: false,
        },
        {
            field: 'DNP',
            headerName: 'DNP',
            width: 60,
            type: 'number',
            editable: false,
        },
        {
            field: 'remaining',
            headerName: 'remaining',
            width: 80,
            type: 'number',
            editable: false,
        },
        {
            field: 'total',
            headerName: 'total',
            width: 60,
            type: 'number',
            editable: false,
        },
        {
            field: 'free',
            headerName: 'free',
            width: 60,
            editable: false,
        },
        {
            field: 'picks',
            headerName: 'picks',
            width: 250,
            editable: false,
            renderCell: (params) => (
                params.row && params.row.picks ?
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '4px', py: '6px' }}>
                        {params.row.picks.map((pick) => (
                            <p key={pick.boardID} style={{ margin: 0, lineHeight: 1, fontSize: '12px' }}>{pick.boardID} - {pick.bet}</p>
                        ))}
                    </Box>
                :
                    null
            )
        },
        {
            field: 'reduced',
            headerName: 'reduction',
            width: 110,
            editable: false,
        },
        {
            field: 'boostInfo',
            headerName: 'boostInfo',
            width: 100,
            editable: false,
        },
        {
            field: 'boostAmount',
            headerName: 'boostAmount',
            width: 100,
            type: 'number',
            editable: false,
        },
        {
            field: 'referralAmount',
            headerName: 'referralAmount',
            width: 110,
            type: 'number',
            editable: false,
        },
        {
            field: 'promoCode',
            headerName: 'promoCode',
            width: 100,
            editable: false,
        },
        {
            field: 'creatorCode',
            headerName: 'creatorCode',
            width: 100,
            editable: false,
        },
        {
            field: 'time',
            headerName: 'time',
            width: 200,
            editable: false,
        },
    ];

    const processRowUpdate = async (newRow) => {
        let updatedRow = { ...newRow, isNew: false };

        return updatedRow;
    };
        
    return (
        <Box sx={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'start', boxSizing: 'border-box', flexDirection: 'column' }}>
            {!isLoading ?
                <ThemeProvider theme={darkTheme}>
                    {entriesData ? 
                        <Box sx={{ display: 'flex', width: '80%', gap: '24px', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'center', alignSelf: 'center', mt: '16px' }}>
                            <Box sx={{ height: 631, width: '100%', bgcolor: '#191919' }}>
                                <DataGrid
                                    sx={{ color: 'white',
                                        '& .MuiDataGrid-filler': {
                                            backgroundColor: '#191919',
                                        }, 
                                        '& .MuiDataGrid-scrollbar--horizontal': {
                                            left: 0
                                        },
                                        '--DataGrid-containerBackground': '#191919',
                                    }}
                                    rows={rows}
                                    getRowHeight={() => 'auto'}
                                    getRowId={(row) => row._id}
                                    columns={columns}
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={setPaginationModel}
                                    processRowUpdate={processRowUpdate}
                                    editMode='row'
                                />
                            </Box>
                        </Box>
                    :
                        null
                    }
                </ThemeProvider>
            : 
                <Skeleton variant="rounded"  width='80%' height='75%' sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', alignSelf: 'center', mt: '16px' }} />
            }
        </Box>
    )
}

export default AdminEntriesDashboard