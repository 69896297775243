import { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';

function BoardCardBet(props) {

    const [higherSelected, setHigherSelected] = useState(false)
    const [lowerSelected, setLowerSelected] = useState(false)

    const handlePick = (choice) => {
        if (props.selectedPicks.length === 0) {
            props.setCartOpen(true)
        }
        // if clicking an already selected button or modifying an already selected pick
        if (higherSelected || lowerSelected || props.selectedPicks.some(pick => pick._id === props.bet._id)) {
            // if clicking an already selected button
            if ((higherSelected && choice === "OVER") || (lowerSelected && choice === "UNDER")) {
                const updatedPicks = props.selectedPicks.filter(pick => pick._id !== props.bet._id)
                props.setSelectedPicks(updatedPicks)
            } 
            // if changing from over to under or under to over
            else {
                const updatedPicks = props.selectedPicks.map(pick => {
                    if (pick._id === props.bet._id) {
                        return {
                            ...pick,
                            bet: choice,
                        }
                    } else {
                        return pick
                    }
                })
                props.setSelectedPicks(updatedPicks)
            }
        } 
        // if selecting pick for a the first time
        else {
            // if trying to add more than 6 picks
            if (props.selectedPicks.length === 6) {
                props.setErrorMsg({type: "picks", msg: "Maximum of 6 picks allowed"})
            }
            // if less than 6 picks already
            else {
                props.setSelectedPicks([...props.selectedPicks, {
                    _id: props.bet._id,  
                    name: props.bet.name,
                    game: props.bet.game,
                    team: props.bet.team,
                    opp: props.bet.opp,
                    pro: props.bet.pro,
                    img: props.bet.img,
                    matchDateTimeEST: props.bet.matchDateTimeEST,
                    maps: props.bet.maps,
                    predicted: props.bet.predicted,
                    special: props.bet?.special ? props.bet?.special : "",
                    stat: props.bet.stat,
                    bet: choice,
                }])
            }
        }
    }

    const checkIfSelected = () => {
        if (!props.selectedPicks) {
            return false
        }
        setHigherSelected(props.selectedPicks.some(pick => pick._id === props.bet._id && pick.bet === "OVER"))
        setLowerSelected(props.selectedPicks.some(pick => pick._id === props.bet._id && pick.bet === "UNDER"))
    }

    useEffect(() => {
        checkIfSelected()
    }, [props.selectedPicks])

    const formatting = ["HEADSHOTS", "FIRST BLOODS", "FIRST DEATHS"]

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', boxSizing: 'border-box', justifyContent: 'space-between', px: '8px' }}>
            <Box sx={{ display: 'flex', boxSizing: 'border-box', flexDirection: {xs:'column', md: 'row'}, alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ display: 'flex', boxSizing: 'border-box', flexDirection: 'row', alignItems: 'center', width: {xs:'100%', md:'auto'} }}>
                    <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 700, fontSize: '18px', color: props.bet?.special === "DISCOUNTED" ? 'gold' : 'white', ml: '5px', mt: {xs:'0px', md:'4px'}, lineHeight: 1.1 }} >{props.bet.predicted}</Typography>
                    <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: {xs: '14px', md: formatting.includes(props.bet.stat) ? '13px' : '14px'}, color: 'white', ml: '5px', mt: {xs:'0px', md:'3px'}, lineHeight: 1.1 }} >{props.bet.stat}</Typography>
                </Box>
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: {xs: '14px', md: formatting.includes(props.bet.stat) ? '13px' : '14px'}, color: 'white', ml:'5px', alignSelf: {xs:'start', md:'auto'}, mt: {xs:'-2px', md:'3px'}, lineHeight: 1.1, }} >MAP{props.bet.maps.length > 1 ? "S" : ""} {props.bet.maps}</Typography>
            </Box>
            <Box sx={{ display: 'flex', boxSizing: 'border-box', flexDirection: 'row', gap: '3px' }}>
                <Button 
                    onClick={() => handlePick('OVER')}
                    className={props.selectedPicks.some(pick => pick._id === props.bet._id && pick.bet === "OVER") ? 'btn-selected' : 'btn-unselected'}
                    sx={{ 
                        width: '60px',
                        minWidth: '60px',
                        height: '48px',
                        borderRadius: '8px 0 0 8px',
                        border: 1,
                        borderColor: 'white',
                        bgcolor: props.selectedPicks.some(pick => pick._id === props.bet._id && pick.bet === "OVER") ? '#A80000' : 'rgba(255, 255, 255, 0.1)',
                        fontFamily: 'Montserrat',
                        fontWeight: 400,
                        fontSize: '12px',
                        color: 'white',
                        textTransform: 'none',
                    }} 
                >
                    OVER
                </Button>
                <Button 
                    disabled={!props.bet.pro}
                    onClick={() => handlePick('UNDER')}
                    className={props.selectedPicks.some(pick => pick._id === props.bet._id && pick.bet === "UNDER") ? 'btn-selected' : 'btn-unselected'}
                    sx={{
                        visibility: props.bet.pro ? 'visible' : 'hidden',
                        width: '60px',
                        minWidth: '60px',
                        height: '48px',
                        borderRadius: '0 8px 8px 0',
                        border: 1,
                        borderColor: 'white',
                        bgcolor: props.selectedPicks.some(pick => pick._id === props.bet._id && pick.bet === "UNDER") ? '#A80000' : 'rgba(255, 255, 255, 0.1)',
                        fontFamily: 'Montserrat',
                        fontWeight: 400,
                        fontSize: '12px',
                        color: 'white',
                        textTransform: 'none',
                    }} 
                >
                    UNDER
                </Button>
            </Box>
        </Box>
    )
}

export default BoardCardBet