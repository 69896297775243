import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Skeleton from '@mui/material/Skeleton';

import AdminBoardDashboard from '../components/AdminBoardDashboard';
import { adminGetGeneral, checkAdmin } from '../APIs';
import AdminUsersDashboard from '../components/AdminUsersDashboard';
import AdminEntriesDashboard from '../components/AdminEntriesDashboard';
import AdminCCDashboard from '../components/AdminCCDashboard';
import AdminLogin from '../components/AdminLogin';

import { supabase } from '../auth/client';
import AdminDepositsDashboard from '../components/AdminDepositsDashboard';
import AdminWithdrawsDashboard from '../components/AdminWithdrawsDashboard';


function AdminPage(props) {

  const [adminSession, setAdminSession] = useState(null)

  const [tab, setTab] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [memberCount, setMemberCount] = useState(0);
  const [verifiedUserCount, setVerifiedUserCount] = useState(0);
  const [betCount, setBetCount] = useState(0);
  const [deposited, setDeposited] = useState(0);
  const [wagered, setWagered] = useState(0);
  const [won, setWon] = useState(0);
  const [paid, setPaid] = useState(0);
  const [overPercent, setOverPercent] = useState(0);
  const [underPercent, setUnderPercent] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const navigate = useNavigate();

  const fetchData = async () => {

    setIsLoading(true)

    const userData = {
      token: adminSession.access_token
    }

    const response = await adminGetGeneral(userData)
    if (response.status === 401) {
      setAdminSession(null)
      const { error } = await supabase.auth.signOut()
      return
    } else if (response.status !== 200) {
      return
    }
    setUserCount(response.data.userCount)
    setMemberCount(response.data.memberCount)
    setVerifiedUserCount(response.data.verifiedUserCount)
    setBetCount(response.data.betCount)
    setDeposited(parseFloat(response.data.totalDeposited.$numberDecimal))
    setWagered(parseFloat(response.data.totalWagered.$numberDecimal))
    setWon(parseFloat(response.data.totalWon.$numberDecimal))
    setPaid(parseFloat(response.data.totalPaid.$numberDecimal))
    setOverPercent(parseFloat(response.data.overPercent))
    setUnderPercent(parseFloat(response.data.underPercent))

    setIsLoading(false)

    return
  }

  const getUserSession = async () => {
    const { data, error } = await supabase.auth.getSession()
    if (data?.session?.user?.id) {
      const userData = {
        token: data?.session?.access_token
      }
      const res = await checkAdmin(userData)
      if (res.status === 200) {
        setAdminSession(data.session) 
      } else {
        setAdminSession(null) 
        const { error } = await supabase.auth.signOut()
        navigate('/admin')
      }
    } else {
      setAdminSession(null) 
      const { error } = await supabase.auth.signOut()
      navigate('/admin')
    }
    return
  }

  const logout = async () => {
    setAdminSession(null)
    const { error } = await supabase.auth.signOut()
    navigate('/admin')
  }

  useEffect(() => {
    getUserSession()
  }, [tab])

  useEffect(() => {
    if (tab === 0 && adminSession) {
      fetchData()
    }
  }, [tab, adminSession])


  return (
    <>
      {adminSession ? 
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', position: 'relative', boxSizing: 'border-box', height: '100%', justifyContent: 'center' }}>
          <Typography onClick={() => logout()} sx={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: '24px', color: '#A80000', cursor: 'pointer', position: 'absolute', top: '-42px', right: '42px' }} >
            Logout
          </Typography>
          <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: '32px', color: 'white' }} >
            AgentPicks Admin Dashboard
          </Typography>
          <Box sx={{ width: '100%', mt: '24px' }}>
            <Tabs TabIndicatorProps={{sx: {bgcolor: '#A80000'} }} value={tab} onChange={handleChange} centered>
              <Tab label="General" sx={{ color: 'white', fontFamily: 'Poppins', "&.Mui-selected": {color: "#A80000"} }} />
              <Tab label="Active Board" sx={{ color: 'white', fontFamily: 'Poppins', "&.Mui-selected": {color: "#A80000"} }}/>
              <Tab label="Full Board" sx={{ color: 'white', fontFamily: 'Poppins', "&.Mui-selected": {color: "#A80000"} }} />
              <Tab label="Users" sx={{ color: 'white', fontFamily: 'Poppins', "&.Mui-selected": {color: "#A80000"} }} />
              <Tab label="Entries" sx={{ color: 'white', fontFamily: 'Poppins', "&.Mui-selected": {color: "#A80000"} }} />
              <Tab label="Deposits" sx={{ color: 'white', fontFamily: 'Poppins', "&.Mui-selected": {color: "#A80000"} }} />
              <Tab label="Withdraw Requests" sx={{ color: 'white', fontFamily: 'Poppins', "&.Mui-selected": {color: "#A80000"} }} />
              <Tab label="Withdraws" sx={{ color: 'white', fontFamily: 'Poppins', "&.Mui-selected": {color: "#A80000"} }} />
              <Tab label="Creator Codes" sx={{ color: 'white', fontFamily: 'Poppins', "&.Mui-selected": {color: "#A80000"} }} />
              <Tab label="Promo Codes" sx={{ color: 'white', fontFamily: 'Poppins', "&.Mui-selected": {color: "#A80000"} }} />
            </Tabs>
          </Box>
          <Box sx={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'start', overflow: 'scroll' }}>
            {tab === 0 ?
              !isLoading ?
                <Box sx={{ display: 'flex', width: '90%', justifyContent: 'center', p: '32px', boxSizing: 'border-box', gap: '24px', alignContent: 'start', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, boxSizing: 'border-box', flexWrap: 'wrap', gap: '24px', justifyContent: 'center' }}>
                    <Box sx={{ display: 'flex', width: '200px', height: '200px', justifyContent: 'center', bgcolor: '#191919', borderRadius: '20px', flexDirection: 'column', boxSizing: 'border-box' }}>
                      <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: '36px', color: 'white' }} >
                        {userCount.toLocaleString()}
                      </Typography>
                      <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: '20px', color: 'white' }} >
                        users
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', width: '200px', height: '200px', justifyContent: 'center', bgcolor: '#191919', borderRadius: '20px', flexDirection: 'column', boxSizing: 'border-box' }}>
                      <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: '36px', color: 'white' }} >
                        {memberCount.toLocaleString()}
                      </Typography>
                      <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: '20px', color: 'white' }} >
                        members
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', width: '200px', height: '200px', justifyContent: 'center', bgcolor: '#191919', borderRadius: '20px', flexDirection: 'column', boxSizing: 'border-box' }}>
                      <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: '36px', color: 'white' }} >
                        {verifiedUserCount.toLocaleString()}
                      </Typography>
                      <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: '20px', color: 'white' }} >
                        verified users
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, boxSizing: 'border-box', flexWrap: 'wrap', gap: '24px', justifyContent: 'center' }}>
                    <Box sx={{ display: 'flex', width: '200px', height: '200px', justifyContent: 'center', bgcolor: '#191919', borderRadius: '20px', flexDirection: 'column', boxSizing: 'border-box' }}>
                      <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: '32px', color: 'white' }} >
                        ${deposited.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </Typography>
                      <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: '20px', color: 'white' }} >
                        deposited
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', width: '200px', height: '200px', justifyContent: 'center', bgcolor: '#191919', borderRadius: '20px', flexDirection: 'column', boxSizing: 'border-box' }}>
                      <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: '32px', color: 'white' }} >
                        {betCount.toLocaleString()}
                      </Typography>
                      <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: '20px', color: 'white' }} >
                        entries
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', width: '200px', height: '200px', justifyContent: 'center', bgcolor: '#191919', borderRadius: '20px', flexDirection: 'column', boxSizing: 'border-box' }}>
                      <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: '32px', color: 'white' }} >
                        ${wagered.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </Typography>
                      <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: '20px', color: 'white' }} >
                        wagered
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', width: '200px', height: '200px', justifyContent: 'center', bgcolor: '#191919', borderRadius: '20px', flexDirection: 'column', boxSizing: 'border-box' }}>
                      <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: '32px', color: 'white' }} >
                        ${won.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </Typography>
                      <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: '20px', color: 'white' }} >
                        won
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', width: '200px', height: '200px', justifyContent: 'center', bgcolor: '#191919', borderRadius: '20px', flexDirection: 'column', boxSizing: 'border-box' }}>
                      <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: '32px', color: 'white' }} >
                        ${paid.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </Typography>
                      <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: '20px', color: 'white' }} >
                        paid
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, boxSizing: 'border-box', flexWrap: 'wrap', gap: '24px', justifyContent: 'center' }}>
                    <Box sx={{ display: 'flex', width: '200px', height: '200px', justifyContent: 'center', bgcolor: '#191919', borderRadius: '20px', flexDirection: 'column', boxSizing: 'border-box' }}>
                      <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: '36px', color: 'white' }} >
                        {overPercent.toFixed(2)}%
                      </Typography>
                      <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: '20px', color: 'white' }} >
                        overs
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', width: '200px', height: '200px', justifyContent: 'center', bgcolor: '#191919', borderRadius: '20px', flexDirection: 'column', boxSizing: 'border-box' }}>
                      <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: '36px', color: 'white' }} >
                        {underPercent.toFixed(2)}%
                      </Typography>
                      <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: '20px', color: 'white' }} >
                        unders
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              :
                <Skeleton variant="rounded"  width='80%' height='75%' sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', alignSelf: 'start', mt: '32px' }} />
            :
              null
            }
            {tab === 1 || tab === 2 ?
              <>
                <AdminBoardDashboard tab={tab} adminSession={adminSession} setAdminSession={setAdminSession} />
              </>
            :
              null
            }
            {tab === 3 ?
              <>
                <AdminUsersDashboard tab={tab} adminSession={adminSession} setAdminSession={setAdminSession} />
              </>
            :
              null
            }
            {tab === 4 ?
              <>
                <AdminEntriesDashboard tab={tab} adminSession={adminSession} setAdminSession={setAdminSession} />
              </>
            :
              null
            }
            {tab === 5 ?
              <>
                <AdminDepositsDashboard tab={tab} adminSession={adminSession} setAdminSession={setAdminSession} />
              </>
            :
              null
            }
            {tab === 6 || tab === 7 ?
              <>
                <AdminWithdrawsDashboard tab={tab} adminSession={adminSession} setAdminSession={setAdminSession} />
              </>
            :
              null
            }


            {tab === 8 ?
              <>
                <AdminCCDashboard tab={tab} adminSession={adminSession} setAdminSession={setAdminSession} />
              </>
            :
              null
            }
          </Box>
        </Box>
      :
        <AdminLogin setAdminSession={setAdminSession} />
      }
    </>
  )
}

export default AdminPage