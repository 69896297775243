import { Box, InputAdornment, Input } from '@mui/material';
import { ImSearch } from 'react-icons/im'; 

const SearchBar = (props) => {

    const handleSearchInputChange = (event) => {
        const value = event.target.value
        // Check if the value length is within the limit of 20
        if (!value || (value.length <= 20 && /^[a-zA-Z0-9-]+$/.test(value))) {
            props.setSearchInput(value)
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                border: 2,
                borderColor: 'rgba(255, 255, 255, 0.2)',
                bgcolor: 'rgba(255, 255, 255, 0.05)',
                borderRadius: '8px',
                textAlign: 'left',
                height: '44px',
                padding: '0 10px', 
                width: '300px',
                boxSizing: 'border-box'
            }}
        >
            <Input
                value={props.searchInput}
                onChange={handleSearchInputChange}
                startAdornment={
                    <InputAdornment position="start">
                        <ImSearch color="gray" />
                    </InputAdornment>
                }
                placeholder="Search"
                disableUnderline 
                fullWidth 
                sx={{
                    fontFamily: 'Poppins',
                    fontWeight: 500,
                    fontSize: '13px',
                    color: 'white',
                }}
            />
        </Box>
    );
};

export default SearchBar;