import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import { getBalances, getPromoBalance, getWalletBalance } from '../APIs';

import { FiGift } from "react-icons/fi";
import { MdOutlineAddBox, MdAccountCircle, MdExpandLess, MdExpandMore } from "react-icons/md";

import { supabase } from '../auth/client';

const pages = ['Board', 'My Entries', 'Promotions'];
const settings = ['Settings', 'How To Play', 'Support', 'EsportsAgent', 'Logout'];

function NavBar(props) {
    const [anchorElNav, setAnchorElNav] = useState(null)
    const [anchorElUser, setAnchorElUser] = useState(null)
    const [walletBalance, setWalletBalance] = useState(null)

    const [firstLoad, setFirstLoad] = useState(true)

    const [expanded, setExpanded] = useState(0)
    

    // used for skeleton components when fetching data from backend
    const [isLoading, setIsLoading] = useState(true)

    const navigate = useNavigate()
    let location = useLocation().pathname.substring(1)

    if (location.endsWith('/')) {
        location = location.slice(0, -1);
    }
    
    const fetchData = async () => {
        setIsLoading(true)
        const response = await getBalances({token: props.session.access_token})

        if (response.status === 401) {
            props.setSession(null)
            setWalletBalance(0)
            props.setPromoBalance(0)
            const { error } = await supabase.auth.signOut()
            navigate('/login')
            return
        } else if (response.status !== 200) {
            setIsLoading(false)
            return
        }

        setWalletBalance(parseFloat(response.walletBalance.$numberDecimal))
        props.setPromoBalance(parseFloat(response.promoBalance.$numberDecimal))
        setIsLoading(false)
        return
    };

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget)
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    };

    const handleDepositBtnClick = () => {
        if (location == 'myentries') {
            props.setOpen(props.isVerified)
        } else{
            props.setOpen(props.isVerified)
            navigate('/myentries')
        }
    }

    const logout = async () => {
        props.setSession(null)
        setWalletBalance(0)
        props.setPromoBalance(0)
        const { error } = await supabase.auth.signOut()
        navigate('/login')
    }

    useEffect(() => {
        if (props.session) {
            fetchData()
        }
        setFirstLoad(false)
    }, [props.session, location])

    return (
        <AppBar sx={{ backgroundColor: 'black'}}>
        <Container sx={{ px: {xs: '0px', sm: '16px', md: '24px'} }} >
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} disableGutters>
            
            {/* START Logo + Title when screen is big*/}
            <Box
                onClick={() => navigate('/')}
                sx={{
                    display: { xs: 'none', md: 'flex' },
                    textDecoration: 'none',
                    justifyContent: 'center', 
                    alignItems: 'center',
                    height: '100%',
                    flexDirection: 'row',
                    cursor: 'pointer'
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                    <img src='/logo.svg' height={'48px'}/>
                    <h1 style={{ fontFamily: 'Montserrat', fontWeight: 800, fontSize: '24px', color: 'white' }} >AGENTPICKS</h1>
                </div>
            </Box>
            {/* END Logo + Title when screen is big*/}

            {/* START Nav buttons when screen is big*/}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, mx: '30px', justifyContent: 'center', gap: '10px' }}>
                {pages.map((page) => (
                    <Box key={page} sx={{ display: 'flex', justifyContent: 'center', width: 'auto', borderBottom: page.replace(/\s/g, '').toLowerCase() === location ? '2px solid #A80000' : page.replace(/\s/g, '').toLowerCase() === 'board' && location === "" ? '2px solid #A80000' : "" }}>
                        <Button
                            onClick={() => {
                                handleCloseNavMenu();
                                if (props.session) {
                                    navigate(`/${page.replace(/\s/g, '').toLowerCase()}`);
                                } else {
                                    if (page === "Board") {
                                        navigate(`/${page.replace(/\s/g, '').toLowerCase()}`);
                                    } else {
                                        navigate('/login')
                                    }
                                }
                            }}
                            sx={{ 
                                my: 1, 
                                color: 'white', 
                                display: 'block', 
                                fontSize: '14px',
                                fontWeight: 'regular',
                                fontFamily: 'Montserrat',
                                mx: '1%',
                                ':hover': {bgcolor: 'rgba(168, 0, 0, 0.5)'},
                                lineHeight: 1.1,
                                textTransform: 'none',
                                whiteSpace: 'nowrap'
                            }}
                        >
                            {page}
                        </Button>
                    </Box>
                ))}
            </Box>
            {/* END Nav buttons when screen is big*/}

            {/* START Right Corner profile buttons when screen is big */}
            {(firstLoad && !props.session) || typeof(props.session) == 'undefined' ?
                <Box sx={{ display: { xs: 'none', md: 'flex' }, height: '100%', width: '244px' }}>
                </Box>
            :
                <Box
                    sx={{
                        display: { xs: 'none', md: 'flex' },
                        justifyContent: 'center', 
                        alignItems: 'center',
                        height: '100%',
                        flexDirection: 'row',
                        gap: '16px',
                        boxSizing: 'border-box',
                        maxWidth: '227px',
                        minWidth: '227px'
                    }}
                >
                    {props.session ? 
                        isLoading && walletBalance === null ?
                            null
                        :
                            <>
                                {/*<Button variant='filled' sx={{
                                    color: '#0D0D0D',
                                    bgcolor: '#267800',
                                    borderRadius: '4px',
                                    fontFamily: 'Montserrat',
                                    fontWeight: 700,
                                    fontSize: '12px',
                                    p: '4px',
                                    width: '58px',
                                    height: '23px',
                                    '&:hover': {
                                        backgroundColor: '#1CC500',
                                    },
                                }}>
                                    Get $25
                                </Button>
                                */}
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                        <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: '14px', color: '#267800' }}>$</Typography>
                                        <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: '14px', color: 'white', ml: '4px' }}>{walletBalance > 0 ? walletBalance.toFixed(2) : '0.00'}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                                        <FiGift color='yellow' fontSize={14} style={{ marginTop: -2 }}  />
                                        <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: '14px', color: 'white', ml: '4px' }}>{props.promoBalance > 0 ? props.promoBalance.toFixed(2) : '0.00'}</Typography>
                                    </Box>
                                </Box>
                                <Box 
                                    onClick={() => handleDepositBtnClick()} 
                                    sx={{ 
                                        display: 'flex', 
                                        cursor: 'pointer', 
                                        '&:hover': {
                                            color: '#1CC500'
                                        },
                                        color: '#267800',
                                    }} 
                                >
                                    <MdOutlineAddBox size={32} />
                                </Box>
                                <Box sx={{ 
                                    color: 'rgba(168, 0, 0, 0.85)', 
                                    '&:hover': {
                                        color: '#A80000',
                                    },
                                    width: '40px', 
                                    height: '40px',
                                    ml: '-6px',
                                }}>
                                    <MdAccountCircle size={'40px'} onClick={(event) => {
                                            handleOpenUserMenu(event)
                                            setExpanded(!expanded)
                                        }}  
                                        cursor='pointer' 
                                    />
                                </Box>
                                <Box 
                                    onClick={(event) => {
                                        handleOpenUserMenu(event)
                                        setExpanded(!expanded)
                                    }} 
                                    sx={{ display: 'flex', ml: '-20px', 
                                        cursor: 'pointer',
                                        color: 'rgba(255, 255, 255, 0.5)', 
                                    }}
                                >
                                    {expanded ? 
                                        <MdExpandLess size={24}/> 
                                    : 
                                        <MdExpandMore size={24}/> 
                                    }
                                </Box>
                                <Box sx={{ width: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center', ml: '-24px', position: 'relative' }}>
                                    <Menu
                                        PaperProps={{sx: {width: '140px', bgcolor: '#191919', color: 'white' }}}
                                        sx={{ mt: '45px', display: { xs: 'none', md: 'flex' } }}
                                        id="menu-appbar3"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={() => {
                                            handleCloseUserMenu()
                                            setExpanded(!expanded)
                                        }}
                                    >
                                        {settings.map((setting) => (
                                            <MenuItem key={setting} sx={{ '&:hover': {bgcolor: 'rgba(255, 255, 255, 0.1)'} }}
                                                onClick={async () => {
                                                    handleCloseUserMenu()
                                                    setExpanded(!expanded)
                                                    if (setting === "Settings") {
                                                        if (props.session) {
                                                            navigate(`/settings`)
                                                        } else {
                                                            navigate("/login")
                                                        }
                                                    } else if (setting === "How To Play") {
                                                        navigate("/howtoplay")
                                                    } else if (setting === "Support") {
                                                        window.open("https://esportsagent.gg/support", "_blank")
                                                    } else if (setting === "EsportsAgent") {
                                                        window.open("https://esportsagent.gg/", "_blank")
                                                    } else if (setting === "Logout") {
                                                        logout()
                                                    }
                                                }
                                            }>
                                                <Typography sx={{ textAlign: 'center', fontFamily: 'Montserrat', fontWeight: '400', fontSize: '14px' }}>{setting}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>
                            </>
                    :
                        <Box sx={{ width: '244px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', boxSizing: 'border-box' }}>
                            <Button variant="contained" onClick={() => navigate('/login')} sx={{ 
                                bgcolor: 'rgba(168, 0, 0, 0.8)', 
                                color: 'white',
                                width: '100px',
                                height: '40px',
                                '&:hover': {
                                    backgroundColor: '#A80000',
                                },  
                                fontFamily: 'Montserrat',
                                fontWeight: 500,
                                fontSize: '13px',
                                borderRadius: '8px',
                                boxSizing: 'border-box',
                                lineHeight: 1,
                                textTransform: 'none'
                            }}>
                                Log In
                            </Button>
                            <Button variant="contained" onClick={() => window.open("https://esportsagent.gg/signup", "_blank")} sx={{ 
                                bgcolor: 'rgba(168, 0, 0, 0.8)', 
                                color: 'white',
                                width: '100px',
                                height: '40px',
                                '&:hover': {
                                    backgroundColor: '#A80000',
                                },
                                fontFamily: 'Montserrat',
                                fontWeight: 500,
                                fontSize: '13px',
                                borderRadius: '8px',
                                boxSizing: 'border-box',
                                lineHeight: 1,
                                textTransform: 'none'
                            }}>
                                Sign Up
                            </Button>
                        </Box>
                    }
                </Box>
            }
            {/* END Right Corner profile buttons when screen is big */}

            {/* START Hamburger when screen is small*/}
            <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 0, width: '48px', justifyContent: 'center' }}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                >
                <MenuIcon />
                </IconButton>
                <Menu
                    PaperProps={{sx: {width: '140px', bgcolor: '#191919', color: 'white' }}}
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        
                    }}
                >
                    <MenuItem key={'Board'} sx={{ '&:hover': {bgcolor: 'rgba(255, 255, 255, 0.1)'} }} onClick={() => {
                        handleCloseNavMenu();
                        navigate(`/board`);
                    }}>
                        <Typography sx={{ textAlign: 'center', fontFamily: 'Montserrat', fontWeight: '400', fontSize: '14px' }}>Board</Typography>
                    </MenuItem>
                    <MenuItem key={'My Entries'} sx={{ '&:hover': {bgcolor: 'rgba(255, 255, 255, 0.1)'} }} onClick={() => {
                        handleCloseNavMenu();
                        if (props.session) {
                            navigate(`/myentries`);
                        } else {
                            navigate('/login')
                        }
                    }}>
                        <Typography sx={{ textAlign: 'center', fontFamily: 'Montserrat', fontWeight: '400', fontSize: '14px' }}>My Entries</Typography>
                    </MenuItem>
                    <MenuItem key={'Promotions'} sx={{ '&:hover': {bgcolor: 'rgba(255, 255, 255, 0.1)'} }} onClick={() => {
                        handleCloseNavMenu();
                        if (props.session) {
                            navigate(`/promotions`);
                        } else {
                            navigate('/login')
                        }
                    }}>
                        <Typography sx={{ textAlign: 'center', fontFamily: 'Montserrat', fontWeight: '400', fontSize: '14px' }}>Promotions</Typography>
                    </MenuItem>
                </Menu>
            </Box>
            {/* END Hamburger when screen is small*/}

            {/* START Logo + Title when screen is small*/}
            <Box
                onClick={() => navigate('/')}
                sx={{
                    display: { sm: 'flex', md: 'none' },
                    textDecoration: 'none',
                    justifyContent: 'center', 
                    alignItems: 'center',
                    height: '100%',
                    flexDirection: 'row',
                    cursor: 'pointer',
                    mr: '2px'
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src='/logo.svg' height={'34px'}/>
                    <h1 style={{ fontFamily: 'Montserrat', fontWeight: 800, fontSize: '18px', color: 'white' }} >AGENTPICKS</h1>
                </div>
            </Box>
            {/* END Logo + Title when screen is small*/}

            {/* START Right Corner profile buttons when screen is small */}
            {(firstLoad && !props.session) || typeof(props.session) == 'undefined' ?
                <Box sx={{ display: { xs: 'flex', md: 'none' }, height: '100%', width: '68px' }}>
                </Box>
            :
                <Box
                    sx={{
                        display: { xs: 'flex', md: 'none' },
                        textDecoration: 'none',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        mr: {xs: '12px', sm: '16px'}
                    }}
                >
                    {props.session ?
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}> 
                            <Box sx={{ 
                                color: 'rgba(168, 0, 0, 0.85)', 
                                '&:hover': {
                                    color: '#A80000',
                                },
                                width: '36px', 
                                height: '36px',
                            }}>
                                <MdAccountCircle size={'100%'} onClick={(event) => {
                                        handleOpenUserMenu(event)
                                        setExpanded(!expanded)
                                    }}  
                                    cursor='pointer' 
                                />
                            </Box>
                            <Box 
                                onClick={(event) => {
                                    handleOpenUserMenu(event)
                                    setExpanded(!expanded)
                                }} 
                                sx={{ display: 'flex', 
                                    cursor: 'pointer',
                                    color: 'rgba(255, 255, 255, 0.5)', 
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mx: '-4px'
                                }}
                            >
                                {expanded ? 
                                    <MdExpandLess size={24}/> 
                                : 
                                    <MdExpandMore size={24}/> 
                                }
                            </Box>
                            <Box sx={{ width: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                                <Menu
                                    PaperProps={{sx: {width: '140px', bgcolor: '#191919', color: 'white' }}}
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar2"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={() => {
                                        handleCloseUserMenu()
                                        setExpanded(!expanded)
                                    }}
                                >
                                    <Box sx={{ 
                                        display: {xs: 'flex', md: 'none'}, 
                                        justifyContent: 'space-around', 
                                        alignItems: 'center',  
                                        width: '100%', 
                                        borderTop: '7px solid #A80000',
                                        mt: '-10px',
                                        borderBottom: '1px solid #464c52',
                                        flexDirection: 'row',
                                        p: '6px',
                                        boxSizing: 'border-box'
                                    }}
                                    >
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 800, fontSize: '14px', color: '#267800' }}>$</Typography>
                                                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '14px', color: 'white', ml: '4px' }}>{walletBalance > 0 ? walletBalance.toFixed(2) : '0.00'}</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                <FiGift color='yellow' fontSize={14} style={{ marginTop: -1 }}  />
                                                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '14px', color: 'white', ml: '4px' }}>{props.promoBalance > 0 ? props.promoBalance.toFixed(2) : '0.00'}</Typography>
                                            </Box>
                                        </Box>
                                        <Box 
                                            onClick={() => handleDepositBtnClick()} 
                                            sx={{ 
                                                display: 'flex', 
                                                cursor: 'pointer', 
                                                '&:hover': {
                                                    color: '#1CC500'
                                                },
                                                color: '#267800',
                                            }} 
                                        >
                                            <MdOutlineAddBox size={32} />
                                        </Box>
                                    </Box>
                                    {settings.map((setting) => (
                                        <MenuItem key={setting} sx={{ '&:hover': {bgcolor: 'rgba(255, 255, 255, 0.1)'} }}
                                            onClick={async () => {
                                                handleCloseUserMenu()
                                                setExpanded(!expanded)
                                                if (setting === "Settings") {
                                                    if (props.session) {
                                                        navigate(`/settings`)
                                                    } else {
                                                        navigate("/login")
                                                    }
                                                } else if (setting === "How To Play") {
                                                    navigate("/howtoplay")
                                                } else if (setting === "Support") {
                                                    window.open("https://esportsagent.gg/support", "_blank")
                                                } else if (setting === "EsportsAgent") {
                                                    window.open("https://esportsagent.gg/", "_blank")
                                                } else if (setting === "Logout") {
                                                    logout()
                                                }
                                            }
                                        }>
                                            <Typography sx={{ textAlign: 'center', fontFamily: 'Montserrat', fontWeight: '400', fontSize: '14px' }}>{setting}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        </Box>
                    :
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', boxSizing: 'border-box', width: 'fit-content' }}>
                            <Button variant="contained" onClick={() => navigate('/login')} sx={{ 
                                bgcolor: 'rgba(168, 0, 0, 0.8)', 
                                color: 'white',
                                width: {xs: '56px', sm: '70px'},
                                minWidth: {xs: '56px', sm: '70px'},
                                height: {xs: '30px', sm: '36px'},
                                '&:hover': {
                                    backgroundColor: '#A80000',
                                },  
                                fontFamily: 'Montserrat',
                                fontWeight: 500,
                                fontSize: {xs: '12px', sm: '13px'},
                                borderRadius: '8px',
                                boxSizing: 'border-box',
                                lineHeight: 1,
                                textTransform: 'none',
                                padding: {xs: '3px', sm: '4px'}
                            }}>
                                Log In
                            </Button>
                        </Box>
                    }
                </Box>
            }
            {/* END Right Corner profile buttons when screen is small*/}

            </Toolbar>
        </Container>
        </AppBar>
    );
}
export default NavBar;