import { Box, InputAdornment, Input, Typography } from '@mui/material';

const BetInput = (props) => {

    const handleChange = (e) => {
        let value = e.target.value
        // Remove any non-numeric characters except for a single decimal point
        value = value.replace(/[^\d.]/g, '')
        // Remove leading zeros
        value = value.replace(/^0+/, '')
        // Remove any negative symbols
        value = value.replace(/-/g, '')
        // Check if the value contains more than 2 decimal places
        if ((value.split('.')[1] || '').length > 2) {
            // Truncate the value to only keep 2 decimal places
            value = props.entryInputValue
        }

        // Check if the value is greater than 999 and doesn't contain a decimal point
        if (parseFloat(value) > 999 && !value.includes('.')) {
            // Set the value to the previous value
            value = props.entryInputValue
        }

        // Update the state with the parsed value
        props.setEntryInputValue(value)
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                border: 1,
                borderColor: 'rgba(255, 255, 255, 0.2)',
                bgcolor: 'rgba(255, 255, 255, 0.05)',
                borderRadius: '8px',
                textAlign: 'left',
                height: '50px',
                padding: {xs:'6px 12px 6px 12px', sm:'8px 16px 4px 16px'}, 
                width: 'auto',
                flexDirection: 'column'
            }}
        >
            <Typography sx={{
                    fontFamily: 'Poppins',
                    fontWeight: 500,
                    fontSize: '13px',
                    color: 'white',
            }}>
                Entry
            </Typography>
            <Input
                type="number"
                startAdornment={
                    <InputAdornment position="start" sx={{ "& .MuiTypography-root": { color: 'white'}, mr: '2px' }}>
                        $
                    </InputAdornment>
                }
                onChange={handleChange}
                placeholder='0'
                value={props.freeEntry ? '0' : props.entryInputValue}
                disabled={props.freeEntry}
                disableUnderline 
                fullWidth 
                sx={{
                    fontFamily: 'Poppins',
                    fontWeight: 500,
                    fontSize: '14px',
                    color: 'white',
                    mt: '4px',
                    "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "white",
                    },
                }}
            />
        </Box>
    );
};

export default BetInput;