import { useState, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';

import { MdExpandLess, MdExpandMore } from "react-icons/md";

import BoardCardBet from './BoardCardBet';
import Logo from './Logo';

import moment from 'moment-timezone';

function BoardCard(props) {

    const sample = props.playerBetsArr[0]

    const [expanded, setExpanded] = useState(false)
    const [readyForAnimations, setReadyForAnimations] = useState(false)

    const extra = props.playerBetsArr.length - 4

    let size = 348

    if (extra > 0) {
        size = ((57 * extra) + 348)
    }

    function convertTime(time) {
        // Create a new Date object with the provided time
        const date = new Date(`1970-01-01T${time}Z`);
    
        // Get the hours and minutes
        let hours = date.getUTCHours();
        let minutes = date.getUTCMinutes();
    
        // Convert to 12-hour format
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
    
        // Return the result
        return `${hours}:${minutes} ${ampm}`;
    }

    const convertToDate = (dateString) => {

        const momentObj = moment(dateString);
        const localDate = moment(momentObj).local()
    
        // Get the individual components
        const weekday = localDate.format('ddd'); // Short weekday name (e.g., "Mon")
        const month = localDate.format('MMM');  // Short month name (e.g., "Jun")
        const day = localDate.date();           // Day of the month (1-31)
        const year = localDate.year();          // Full year (e.g., 2024)
    
        // Add a '.' after the month if it's not May
        let formattedMonth = month;
        if (month !== "May") {
            formattedMonth += ".";
        }
    
        // Format the date
        const matchDate = `${weekday}, ${formattedMonth} ${day} ${year}`;
    
        return matchDate;
    };

    const convertToTime = (dateString) => {

        const momentObj = moment(dateString);
        const localMoment = moment(momentObj).local();
    
        // Format the time
        const matchTime = localMoment.format('h:mm A');
    
        return matchTime;
    };


    useEffect(() => {
        const timer = setTimeout(() => {
          setReadyForAnimations(true)
        }, 500)
    }, [])

    return (
        <animated.div style={useSpring({
            height: expanded ? `${size}px` : '348px',
            from: { height: '348px' },
            immediate: !readyForAnimations
          })}>
            <Box sx={{ 
                width: {xs: '300px', md: '355px' },
                height: '100%',
                overflow: 'hidden',
                borderRadius: '8px', 
                border: 1, 
                borderColor: 'rgba(255, 255, 255, 0.2)',
                bgcolor: 'rgba(255, 255, 255, 0.05)', 
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                pb: expanded ? '49px' : 0
            }} >
                <Box sx={{ display: 'flex', flexDirection: 'row', py: '16px', pl: {xs:'10px', md:'16px'}, pr: {xs:'12px', md:'16px'}, justifyContent: 'space-between', height: '48px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Box sx={{ height: '48px', maxWidth: '53px', width: '53px' }}>
                            <Logo teamColor={sample.img} />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', px: {xs:'6px', md:'8px'}, justifyContent: 'start', pt: {xs:0, md:'2px'}, maxWidth: {xs:'130px', md:'160px'} }}>
                            <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: {xs:'17px', md:'18px'}, color: 'white', lineHeight: 1.1, textAlign: 'left' }} >{sample.name}</Typography>
                            <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '14px', color: 'white' }}>{sample.team.toUpperCase()}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center' }}>
                        <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '13px', color: 'white', lineHeight: 1.2 }}>{convertToDate(sample.matchDateTimeEST).slice(0, -5)}</Typography>
                        <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '13px', color: 'white', lineHeight: 1.2 }}>at {convertToTime(sample.matchDateTimeEST)}</Typography>
                        <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '14px', color: 'white', lineHeight: 1.2 }}>vs {sample.opp.toUpperCase()}</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', boxSizing: 'border-box', gap: '9px' }} >
                    {props.playerBetsArr.map((singleSpecificBet) => (
                        <BoardCardBet key={singleSpecificBet._id} bet={singleSpecificBet} selectedPicks={props.selectedPicks} setSelectedPicks={props.setSelectedPicks} setCartOpen={props.setCartOpen} setErrorMsg={props.setErrorMsg} />
                    ))}
                </Box>
                {
                    props.playerBetsArr.length > 4 && !expanded &&
                        <Box onClick={() => setExpanded(!expanded)} sx={{ display: 'flex', 
                            flexDirection: 'row', boxSizing: 'border-box', 
                            justifyContent: 'center', cursor: 'pointer', 
                            position: 'absolute', bottom: 0, width: {xs: '300px', md: '350px' }, 
                            height: '40px', bgcolor: 'rgb(25 25 25)', zIndex: 2, 
                            alignItems: 'center', borderTop: '1px solid rgba(255, 255, 255, 0.2)', 
                            '&:hover': {backgroundColor: '#303030'} 
                        }}>
                            <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '16px', color: 'white' }} >More Picks ({props.playerBetsArr.length - 4}) <MdExpandMore size={24} style={{ marginBottom: -6 }}/> </Typography>
                        </Box>
                }
                {
                    props.playerBetsArr.length > 4 && expanded &&
                        <Box onClick={() => setExpanded(!expanded)} sx={{ display: 'flex', 
                            flexDirection: 'row', boxSizing: 'border-box', 
                            justifyContent: 'center', cursor: 'pointer', 
                            position: 'absolute', bottom: 0, width: {xs: '300px', md: '350px' }, 
                            height: '40px', bgcolor: 'rgb(25 25 25)', zIndex: 2, 
                            alignItems: 'center', borderTop: '1px solid rgba(255, 255, 255, 0.2)', 
                            '&:hover': {backgroundColor: '#303030'} 
                        }}>
                            <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '16px', color: 'white' }} >Less Picks <MdExpandLess size={24} style={{ marginBottom: -6 }}/> </Typography>
                        </Box>
                }

            </Box>
        </animated.div>
    );
}
    
export default BoardCard;