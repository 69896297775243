import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { animated, useSpring } from 'react-spring';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";

import { MdExpandLess, MdExpandMore } from "react-icons/md";
import EarningsGraph from './EarningsGraph';

function CCPromotionCard(props) {

    const [expanded1, setExpanded1] = useState(false)

    const [readyForAnimations, setReadyForAnimations] = useState(false)

    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
          setReadyForAnimations(true)
        }, 500)
      }, [])

    return (
        <animated.div style={useSpring({
            height: expanded1 ? '720px' : '300px',
            from: { height: '300px' },
            immediate: !readyForAnimations
        })}>
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'start', 
                boxSizing: 'border-box', 
                bgcolor: '#191919', 
                width: '350px', 
                borderRadius: '8px',
                height: '100%',
                position: 'relative',
                overflow: 'hidden',
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', boxSizing: 'border-box', width: '100%', px: '16px', height: '184px', py: '28px', minHeight: '184px' }} >
                    {!props.creatorCode ?
                        <>
                            <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '16px', color: 'white', alignSelf: 'start' }}>
                                Add Creator Code
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', boxSizing: 'border-box', width: '100%', mt: '12px', px: '8px' }}>
                                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 900, fontSize: '13px', color: '#A80000', alignSelf: 'start', lineHeight: 1.1 }}>
                                    •
                                </Typography>
                                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: '13px', color: 'white', alignSelf: 'start', ml: '4px', lineHeight: 1.1, textAlign: 'left' }}>
                                    Add a creator code to boost winnings by 3%
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', boxSizing: 'border-box', width: '100%', mt: '12px', px: '8px' }}>
                                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 900, fontSize: '13px', color: '#A80000', alignSelf: 'start', lineHeight: 1.1 }}>
                                    •
                                </Typography>
                                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: '13px', color: 'white', alignSelf: 'start', ml: '4px', lineHeight: 1.1 }}>
                                    Cannot be combined with other boosts
                                </Typography>
                            </Box>
                        </>
                    :
                        <>
                            <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '16px', color: 'white', alignSelf: 'start' }}>
                                Creator Code Added
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', boxSizing: 'border-box', width: '100%', mt: '12px', px: '8px', gap: '12px' }}>
                                <img src='/logo.svg' height={'68px'}/>
                                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: '18px', color: '#A80000', lineHeight: 1.1, textAlign: 'center', alignSelf: 'center' }}>
                                    {props.creatorCode}: +3%
                                </Typography>
                            </Box>
                        </>
                    }
                </Box>
                <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', boxSizing: 'border-box', gap: '12px', height: '56px', minHeight: '56px' }}>
                    {/*<Button variant="contained" onClick={() => window.open("https://esportsagent.gg", "_blank")} sx={{ 
                        bgcolor: '#252525', 
                        color: 'white',
                        width: '120px',
                        height: '44px',
                        '&:hover': {
                            backgroundColor: '#303030',
                        },
                        fontFamily: 'Montserrat',
                        fontWeight: 500,
                        fontSize: '12px',
                        borderRadius: '8px',
                        boxSizing: 'border-box',
                        lineHeight: 1.1,
                        px: '4px'
                    }}>
                        Learn More
                    </Button> */}
                    <Button variant="contained" onClick={() => navigate('/settings')} sx={{ 
                        bgcolor: 'rgba(168, 0, 0, 0.8)', 
                        color: 'white',
                        width: '120px',
                        height: '44px',
                        '&:hover': {
                            backgroundColor: '#A80000',
                        },
                        fontFamily: 'Montserrat',
                        fontWeight: 500,
                        fontSize: '12px',
                        borderRadius: '8px',
                        boxSizing: 'border-box',
                        lineHeight: 1.1,
                        px: '4px'
                    }}>
                        {!props.creatorCode ? 'Add Code' : 'Modify' }
                    </Button>
                </Box>
                {props.creatorCode ? 
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', boxSizing: 'border-box', width: '100%', px: '16px', height: '440px', py: '12px', minHeight: '440px' }} >
                        <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '16px', color: 'white', alignSelf: 'start', mb: '12px' }}>
                            Creator Code Bonus Earnings
                        </Typography>
                        <EarningsGraph data={props.monthlyCCEarnings} />
                        <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '14px', color: 'white', alignSelf: 'center', mt: '12px' }}>
                            Last 6 Month Earnings: ${(props.monthlyCCEarnings.reduce((sum, item) => sum + item.amt, 0)).toFixed(2)}
                        </Typography>
                        <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '14px', color: 'white', alignSelf: 'center' }}>
                            Total Earnings: ${props.creatorCodeEarnings.toFixed(2)}
                        </Typography>
                    </Box>
                :
                    null
                }
                {props.creatorCode ? 
                    <Box onClick={() => {setExpanded1(!expanded1)}} sx={{ display: 'flex', 
                        flexDirection: 'row', 
                        justifyContent: 'center', 
                        boxSizing: 'border-box', 
                        width: '100%', 
                        borderTop: '1px solid rgba(255, 255, 255, 0.2)', 
                        height: '48px', cursor: 'pointer', 
                        alignItems: 'center', 
                        position: 'absolute',
                        bottom: 0, 
                        zIndex: 2,
                        bgcolor: '#191919',
                        '&:hover': {
                            backgroundColor: '#303030',
                        },
                    }}>
                        {expanded1 ?
                            <>
                                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '16px', color: 'white' }} >
                                    Hide Earnings
                                <MdExpandLess size={24} style={{ marginBottom: -6 }}/> 
                                </Typography>
                            </>
                        :
                            <>
                                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '16px', color: 'white' }} >
                                    Show Earnings
                                <MdExpandMore size={24} style={{ marginBottom: -6 }}/> 
                                </Typography>
                            </>
                        }
                    </Box>
                :
                    null
                }
            </Box>
        </animated.div>
    )
}

export default CCPromotionCard