import { useEffect, useState } from "react";

import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";


function HowToPlayPage(props) {

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: '36px', width: '100%', alignItems: 'center', position: 'relative', boxSizing: 'border-box', pb: '130px' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: '32px', color: 'white' }} >How To Play</Typography>
            <Box sx={{ display: 'flex', width: {xs:'98%', sm:'90%'}, maxWidth: '800px' , mt: '48px', gap: '16px', flexDirection: 'column'}}>
                <Typography  sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '28px', color: 'white', lineHeight: 1.2 }} >1. Sign Up on EsportsAgent.gg</Typography>
                <Box sx={{ alignSelf: 'center' }}>
                    <img src='/demo1.png' height={600} width={380} />
                </Box>
                <Typography  sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '28px', color: 'white', lineHeight: 1.2 }} >2. Log In on AgentPicks</Typography>
                <Box sx={{ alignSelf: 'center' }}>
                    <img src='/demo2.png' height={600} width={380} />
                </Box>
                <Typography  sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '28px', color: 'white', lineHeight: 1.2 }} >3. Verify Your Identity</Typography>
                <Box sx={{ alignSelf: 'center' }}>
                    <img src='/demo3.png' height={600} width={380} />
                </Box>
                <Typography  sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '28px', color: 'white', lineHeight: 1.2 }} >4. Complete Your Deposit</Typography>
                <Box sx={{ alignSelf: 'center' }}>
                    <img src='/demo4.png' height={600} width={380} />
                </Box>
                <Typography  sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '28px', color: 'white', lineHeight: 1.2 }} >5. Pick 2-6 Player Over/Unders</Typography>
                <Box sx={{ alignSelf: 'center' }}>
                    <img src='/demo5.png' height={600} width={380} />
                </Box>
                <Typography  sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '28px', color: 'white', lineHeight: 1.2 }} >6. Choose Your Entry Amount</Typography>
                <Box sx={{ alignSelf: 'center' }}>
                    <img src='/demo6.png' height={600} width={380} />
                </Box>
                <Typography  sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '28px', color: 'white', lineHeight: 1.2 }} >7. Submit Your Picks and Win BIG!</Typography>
                <Box sx={{ alignSelf: 'center' }}>
                    <img src='/demo7.png' height={600} width={380} />
                </Box>
                <Typography  sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '28px', color: 'white', lineHeight: 1.2, mt: '48px' }} >FULL TUTORIAL</Typography>
                <div className="video-responsive">
                    <iframe
                        src={`https://www.youtube.com/embed/QKzpWeXvZCQ`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded YouTube"
                    ></iframe>
                </div>
            </Box>
        </Box>
    )
}
  
export default HowToPlayPage;