
import Button from '@mui/material/Button';

import { TbActivityHeartbeat } from "react-icons/tb";

function CartBtn(props) {

    return (
        <div>
            <Button
                variant='outlined'
                size='large'
                onClick={() => {
                    props.setCartOpen(!props.cartOpen)
                }}
                sx={{
                    width: '40px',
                    minWidth: 0,
                    height: '28px', 
                    fontWeight: 'regular', 
                    fontFamily: 'Poppins',
                    color: '#A80000', 
                    borderRadius: '15px',
                    border: 2,
                    borderColor: '#474747', 
                    display: 'flex', 
                    bgcolor: '#191919', 
                    padding: 0,
                    '&:hover': {
                        bgcolor: '#474747', 
                        borderColor: '#474747',
                    }
                }}
            >
                <TbActivityHeartbeat size={24} />
            </Button>
        </div>
    );
}
    
export default CartBtn;