import { Box, InputAdornment, Input, Typography } from '@mui/material';

const BetOutput = (props) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                border: 1,
                borderColor: '#A80000',
                bgcolor: 'rgba(168, 0, 0, 0.05)',
                borderRadius: '8px',
                textAlign: 'left',
                height: '50px',
                padding: {xs:'6px 12px 6px 12px', sm:'8px 16px 4px 16px'}, 
                width: 'auto',
                minWidth: '64px',
                ml: {xs:'6px', sm:'8px'}, 
                flexDirection: 'column'
            }}
        >
            <Typography sx={{
                    fontFamily: 'Poppins',
                    fontWeight: 500,
                    fontSize: '13px',
                    color: 'white',
            }}>
                To Win
            </Typography>
            <Input
                startAdornment={
                    <InputAdornment position="start" sx={{ "& .MuiTypography-root": { color: 'white'}, mr: '2px' }}>
                        $
                    </InputAdornment>
                }
                disableUnderline 
                value={props.entryInputValue.toFixed(2)}
                fullWidth 
                sx={{
                    fontFamily: 'Poppins',
                    fontWeight: 500,
                    fontSize: '14px',
                    color: 'white',
                    mt: '4px'
                }}
            />
        </Box>
    );
};

export default BetOutput;