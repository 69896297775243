import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { MdExpandLess, MdExpandMore } from "react-icons/md";
import EntrySummaryCard from './EntrySummaryCard';

import { supabase } from '../auth/client';
import { getPicks } from "../APIs";
import EntrySummarySkeleton from "../skeletons/EntrySummarySkeleton";

function EntrySummary(props) {

    const [picks, setPicks] = useState(null)
    const [expanded, setExpanded] = useState(props.index === 0 && props.currentPage === 1)

    const [isLoading, setIsLoading] = useState(true)

    const navigate = useNavigate();

    // gets board player data from entry/bet id
    const fetchPicks = async () => {
        setIsLoading(true)
        const response = await getPicks({
            id: props.bet._id, 
            token: props.session.access_token
        })
        
        if (response.status === 401) {
            props.setSession(null)
            const { error } = await supabase.auth.signOut()
            navigate('/login')
            return
        } else if (response.status !== 200) {
            setIsLoading(false)
            return
        }

        setPicks(response.picks)
        setIsLoading(false)

        return
    }


    useEffect(() => {
        if (props.session) {
            fetchPicks()
            return
        }
    }, [props.session])

    let multiplier

    if (props.type === "PAST" || props.type === "FREE") {
        // TODO: can combine these 2 once all bets have reduced property
        if (props.bet?.reduced > 0) {
            if (props.bet.wins === 1) {
                multiplier = (1.50 * (1.00 - props.bet?.reduced)) + ((1.50 * (1.00 - props.bet?.reduced)) * props.bet.boostPercent);
            } else if (props.bet.wins === 2) {
                multiplier = (3.00 * (1.00 - props.bet?.reduced)) + ((3.00 * (1.00 - props.bet?.reduced)) * props.bet.boostPercent);
            } else if (props.bet.wins === 3) {
                multiplier = (5.00 * (1.00 - props.bet?.reduced)) + ((5.00 * (1.00 - props.bet?.reduced)) * props.bet.boostPercent);
            } else if (props.bet.wins === 4) {
                multiplier = (10.00 * (1.00 - props.bet?.reduced)) + ((10.00 * (1.00 - props.bet?.reduced)) * props.bet.boostPercent);
            } else if (props.bet.wins === 5) {
                multiplier = (17.00 * (1.00 - props.bet?.reduced)) + ((17.00 * (1.00 - props.bet?.reduced)) * props.bet.boostPercent);
            } else if (props.bet.wins === 6) {
                multiplier = (28.00 * (1.00 - props.bet?.reduced)) + ((28.00 * (1.00 - props.bet?.reduced)) * props.bet.boostPercent);
            }
        } else {
            if (props.bet.wins === 1) {
                multiplier = 1.50 + (1.50 * props.bet.boostPercent);
            } else if (props.bet.wins === 2) {
                multiplier = 3.00 + (3.00 * props.bet.boostPercent);
            } else if (props.bet.wins === 3) {
                multiplier = 5.00 + (5.00 * props.bet.boostPercent);
            } else if (props.bet.wins === 4) {
                multiplier = 10.00 + (10.00 * props.bet.boostPercent);
            } else if (props.bet.wins === 5) {
                multiplier = 17.00 + (17.00 * props.bet.boostPercent);
            } else if (props.bet.wins === 6) {
                multiplier = 28.00 + (28.00 * props.bet.boostPercent);
            }
        }
    } else {
        // TODO: can combine these 2 once all bets have reduced property
        if (props.bet?.reduced > 0) {
            if (props.bet.picks.length === 2) {
                multiplier = (3.00 * (1.00 - props.bet?.reduced)) + ((3.00 * (1.00 - props.bet?.reduced)) * props.bet.boostPercent);
            } else if (props.bet.picks.length === 3) {
                multiplier = (5.00 * (1.00 - props.bet?.reduced)) + ((5.00 * (1.00 - props.bet?.reduced)) * props.bet.boostPercent);
            } else if (props.bet.picks.length === 4) {
                multiplier = (10.00 * (1.00 - props.bet?.reduced)) + ((10.00 * (1.00 - props.bet?.reduced)) * props.bet.boostPercent);
            } else if (props.bet.picks.length === 5) {
                multiplier = (17.00 * (1.00 - props.bet?.reduced)) + ((17.00 * (1.00 - props.bet?.reduced)) * props.bet.boostPercent);
            } else if (props.bet.picks.length === 6) {
                multiplier = (28.00 * (1.00 - props.bet?.reduced)) + ((28.00 * (1.00 - props.bet?.reduced)) * props.bet.boostPercent);
            }
        } else {
            if (props.bet.picks.length === 2) {
                multiplier = 3.00 + (3.00 * props.bet.boostPercent);
            } else if (props.bet.picks.length === 3) {
                multiplier = 5.00 + (5.00 * props.bet.boostPercent);
            } else if (props.bet.picks.length === 4) {
                multiplier = 10.00 + (10.00 * props.bet.boostPercent);
            } else if (props.bet.picks.length === 5) {
                multiplier = 17.00 + (17.00 * props.bet.boostPercent);
            } else if (props.bet.picks.length === 6) {
                multiplier = 28.00 + (28.00 * props.bet.boostPercent);
            }
        }
    }

    multiplier = (Math.round(multiplier * 100) / 100);
    

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', borderBottom: '2px solid rgba(255, 255, 255, 0.2)', pb: '8px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', minHeight: {xs: '84px', sm:'94px'} }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ color: 'white', fontSize: {xs:'20px', sm:'24px'}, fontFamily: 'Poppins', fontWeight: 500 }}>
                        {props.bet.picks.length}-Pick {props.bet.free ? "Free" : ""} Entry
                    </Typography>
                    <Typography sx={{ color: 'white', fontSize: {xs:'16px', sm:'18px'}, fontFamily: 'Poppins', fontWeight: 400, textAlign: 'left' }}>
                        ${parseFloat(props.bet.betAmount.$numberDecimal).toFixed(2)}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', alignItems: 'end' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: '8px', borderRadius: '8px', bgcolor: props.bet.remaining === 0 && props.bet.losses === 0 && props.bet.wins >= 1 ? '#1CC500' : '#858585', minWidth: {xs: '52px', sm:'66px'}, width: 'fit-content' }}>
                        <Typography sx={{ color: '#0D0D0D', fontSize: {xs:'14px', sm:'16px'}, fontFamily: 'Montserrat', fontWeight: 700, }}>
                            {props.bet.remaining !== 0 ?
                                "IN PROGRESS"
                            :
                                props.bet.losses === 0 && props.bet.wins < 1 && !props.bet.free ?
                                    "REFUNDED"
                                :
                                    props.bet.losses === 0 && props.bet.wins >= 1?
                                        "WIN!"
                                    :
                                        "LOSS"
                            }
                        </Typography>
                    </Box>
                    <Box sx={{ minHeight: '36px', flexDirection: 'column', display: 'flex', }}>
                        <Typography sx={{ color: 'white', fontSize: {xs:'20px', sm:'24px'}, fontFamily: 'Poppins', fontWeight: 500, textAlign: 'end'}}>
                            {props.type === "PAST" && props.bet.wins > 0 && props.bet.losses === 0 && props.bet.remaining === 0 ?
                                `+$${parseFloat(props.bet.totalWinAmount.$numberDecimal).toFixed(2)}`
                            :
                                null
                            }
                        </Typography>
                        <Typography sx={{ color: 'white', fontSize: {xs:'13px', sm:'15px'}, fontFamily: 'Poppins', fontWeight: 400, textAlign: 'end', mt: '-6px' }}>
                            {props.type === "PAST" && props.bet.wins > 0 && props.bet.losses === 0 && props.bet.remaining === 0 && !props.bet.free ?
                                `${(parseFloat(props.bet.totalWinAmount.$numberDecimal) / parseFloat(props.bet.betAmount.$numberDecimal)).toFixed(2)}x`
                            :
                                null
                            }
                        </Typography>
                        <Typography sx={{ color: 'white', fontSize: {xs:'13px', sm:'15px'}, fontFamily: 'Poppins', fontWeight: 400, textAlign: 'end', mt: '-2px' }}>
                            {props.type === "PAST" && props.bet.wins > 0 && props.bet.losses === 0 && props.bet.remaining === 0 && props.bet.free ?
                                `${props.bet.wins}/6`
                            :
                                null
                            }
                        </Typography>



                        <Typography sx={{ color: 'white', fontSize: {xs:'20px', sm:'24px'}, fontFamily: 'Poppins', fontWeight: 500, textAlign: 'end'}}>
                            {props.type !== "PAST" && props.bet.remaining !== 0 && props.bet.free ?
                                `for $20.00`
                            :
                                null
                            }
                        </Typography>


                        <Typography sx={{ color: 'white', fontSize: {xs:'20px', sm:'24px'}, fontFamily: 'Poppins', fontWeight: 500, textAlign: 'end'}}>
                            {props.type === "OPEN" && props.bet.remaining !== 0 && !props.bet.free ?
                                `for $${(parseFloat(props.bet.betAmount.$numberDecimal) * multiplier).toFixed(2)}`
                            :
                                null
                            }
                        </Typography>
                        <Typography sx={{ color: 'white', fontSize: {xs:'13px', sm:'15px'}, fontFamily: 'Poppins', fontWeight: 400, textAlign: 'end', mt: '-6px' }}>
                            {props.type === "OPEN" && props.bet.remaining !== 0 && !props.bet.free ?
                                `${multiplier.toFixed(2)}x`
                            :
                                null
                            }
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {isLoading ?
                    expanded ?
                        <>
                            <Box onClick={() => setExpanded(!expanded)} sx={{ display: 'flex', flexDirection: 'row', boxSizing: 'border-box', justifyContent: 'left', cursor: 'pointer', alignItems: 'center' }}>
                                <Typography sx={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: {xs:'14px', sm:'16px'}, fontFamily: 'Montserrat', fontWeight: 500 }} > 
                                    PICKS
                                    <MdExpandLess size={24} style={{ marginBottom: -6 }}/> 
                                </Typography>
                            </Box>
                            <EntrySummarySkeleton />
                        </>
                    :
                        <Box onClick={() => setExpanded(!expanded)} sx={{ display: 'flex', flexDirection: 'row', boxSizing: 'border-box', justifyContent: 'left', cursor: 'pointer', alignItems: 'center' }}>
                            <Typography sx={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: {xs:'14px', sm:'16px'}, fontFamily: 'Montserrat', fontWeight: 500 }} > 
                                PICKS
                                {expanded ? 
                                    <MdExpandLess size={24} style={{ marginBottom: -6 }}/> 
                                : 
                                    <MdExpandMore size={24} style={{ marginBottom: -6 }}/> 
                                }
                            </Typography>
                        </Box>
                :
                    <>
                        <Box onClick={() => setExpanded(!expanded)} sx={{ display: 'flex', flexDirection: 'row', boxSizing: 'border-box', justifyContent: 'left', cursor: 'pointer', alignItems: 'center' }}>
                            <Typography sx={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: {xs:'14px', sm:'16px'}, fontFamily: 'Montserrat', fontWeight: 500 }} > 
                                PICKS
                                {expanded ? 
                                    <MdExpandLess size={24} style={{ marginBottom: -6 }}/> 
                                : 
                                    <MdExpandMore size={24} style={{ marginBottom: -6 }}/> 
                                }
                            </Typography>
                        </Box>
                        {picks && expanded && picks.map((pickObj) => (
                            <EntrySummaryCard key={pickObj._id + props.bet._id} pickObj={pickObj} />
                        ))}
                    </>
                }
            </Box>

        </Box>
    )
}

export default EntrySummary