import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';

import { adminDeletePlayer, adminEditPlayer, adminEditPlayerLine, adminGetBoard } from '../APIs';
import AdminBoardDialog from './AdminBoardDialog';
import AdminAreYouSureDialog from './AdminAreYouSureDialog';

import { supabase } from '../auth/client';

function AdminBoardDashboard(props) {

    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
        },
    });

    const [boardData, setBoardData] = useState(null);

    // used for skeleton components when fetching data from backend
    const [isLoading, setIsLoading] = useState(true);
    const [update, setUpdate] = useState(false);
    const [errorMsg, setErrorMsg] = useState({})

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [confirmUpdate, setConfirmUpdate] = useState(null);

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    });

    const fetchBoardData = async () => {
        setIsLoading(true)
        const userData = {
            token: props.adminSession.access_token,
            active: true
        }

        const data = await adminGetBoard(userData)
        if (data.status === 401) {
            props.setAdminSession(null)
            const { error } = await supabase.auth.signOut()
            return
        } else if (data.status !== 200) {
          return
        }
        setBoardData(data.board)
        setIsLoading(false)
        return
    }

    const fetchBoardData2 = async () => {
        setIsLoading(true)
        const userData = {
            token: props.adminSession.access_token,
            active: false
        }

        const data = await adminGetBoard(userData)
        if (data.status === 401) {
            props.setAdminSession(null)
            const { error } = await supabase.auth.signOut()
            return
        } else if (data.status !== 200) {
          return
        }
        setBoardData(data.board)
        setIsLoading(false)
        return
    }

    const rows = boardData

    const columns = [
        {   
            field: '_id', 
            headerName: 'id', 
            width: 100,
        },
        {
            field: 'name',
            headerName: 'name',
            width: 120,
            editable: false,
        },
        {
            field: 'game',
            headerName: 'GAME',
            width: 70,
            editable: true,
        },
        {
            field: 'team',
            headerName: 'TEAM',
            width: 70,
            editable: true,
        },
        {
            field: 'img',
            headerName: 'COLOR',
            width: 90,
            editable: true,
        },
        {
            field: 'opp',
            headerName: 'OPP',
            width: 70,
            editable: true,
        },
        {
            field: 'matchDateTimeEST',
            headerName: 'DATETIME (EST)',
            width: 210,
            editable: true,
        },
        {
            field: 'pro',
            headerName: 'pro',
            width: 70,
            editable: false,
        },
        {
            field: 'maps',
            headerName: 'maps',
            width: 80,
            editable: false,
        },
        {
            field: 'stat',
            headerName: 'stat',
            width: 90,
            editable: false,
        },
        {
            field: 'predicted',
            headerName: 'PREDICTED',
            type: 'number',
            width: 80,
            editable: true,
        },
        {
            field: 'actual',
            headerName: 'ACTUAL',
            type: 'number',
            width: 80,
            editable: true,
            renderCell: (params) => {
                return params.value === null ? 'null' : params.value;
            },
        },
        {
            field: 'expired',
            headerName: 'EXPIRED',
            width: 80,
            editable: true,
            type: 'singleSelect',
            valueOptions: [false, true]
        },
        {
            field: 'finished',
            headerName: 'FINISHED',
            width: 90,
            editable: true,
            type: 'singleSelect',
            valueOptions: [false, true]
        },
        {
            field: 'pickedUnder',
            headerName: 'unders',
            type: 'number',
            width: 60,
            editable: false,
        },
        {
            field: 'pickedOver',
            headerName: 'overs',
            type: 'number',
            width: 60,
            editable: false,
        },
        {
            field: 'special',
            headerName: 'special',
            width: 120,
            editable: false,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Delete',
            width: 70,
            cellClassName: 'actions',
            getActions: ({ id }) => {
      
              return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={() => handleDeleteClick(id)}
                        color="inherit"
                    />,
              ];
            },
        },
    ];

    const handleDeleteClick = async (id) => {
        setIsLoading(true)
        const userData = {
            id: id,
            token: props.adminSession.access_token
        }
        const response = await adminDeletePlayer(userData)
        if (response.status === 401) {
            props.setAdminSession(null)
            const { error } = await supabase.auth.signOut()
            return
        } else if (response.status === 404) {
            setErrorMsg({type: 'delete', msg: 'Player not found (probably already deleted so try refreshing)'})
            return
        } else if (response.status !== 200) {
            setErrorMsg({type: 'delete', msg: 'Cannot delete because someone bet on this player already. It will ruin their bet if deleted. Try editing instead.'})
            return
        }
        setUpdate(!update)
        setIsLoading(false)
        return
    };

    const gameOptions = ['COD', 'HALO', 'WZ', 'CS2', 'VAL', 'APEX', 'LOL', 'RL', 'DOTA2', 'R6']

    const statOptions = ['KILLS', 'DEATHS', 'KD', 'KDA', 'HILL TIME', 'DMG', 'FIRST BLOODS', 'FIRST DEATHS', 'RATING', 'HEADSHOTS', 'ASSISTS', 'ADR', 'ACS', 'GOALS', 'SAVES', 'SHOTS', 'DEMOS', 'ELIMS']

    function isValidString(input, minLength, maxLength) {
        return typeof input === 'string' && input.length >= minLength && input.length <= maxLength;
    }
    
    function isValidGame(input) {
        return gameOptions.includes(input);
    }

    function isValidStat(input) {
        return statOptions.includes(input);
    }
    
    function isValidMaps(input) {
        const regex = /^(\d+(-\d+)?)$/;
        return regex.test(input);
    }
    
    function isValidNumber(input) {
        const number = Number(input);
        return !isNaN(number) && number >= -1 && number <= 999999;
    }
    
    function isValidBoolean(input) {
        return typeof input === 'boolean';
    }

    // Check if value is empty, null, undefined, or NaN
    function isValidValue(value) {
        return value !== null && value !== undefined && value !== '' && !Number.isNaN(value);
    }

    function isValidDateTimeEST(value) {
        // Test if the value matches the regex pattern
        const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:00-\d{2}:00$/;
        if (!regex.test(value)) {
            return false;
        }

        // Extract date and time components
        const [datePart, timePart] = value.split('T');
        const [year, month, day] = datePart.split('-').map(Number);
        const [hour, minute] = timePart.split(':').slice(0, 2).map(Number);

        // Check if the date and time components form a valid date and time
        const date = new Date(year, month - 1, day, hour, minute);

        return date.getFullYear() === year &&
            date.getMonth() === month - 1 &&
            date.getDate() === day &&
            date.getHours() === hour &&
            date.getMinutes() === minute;
    }

    
    function verifyUserData(userData) {
    
        if (!isValidValue(userData.name) || !isValidString(userData.name, 2, 40)) {
            setErrorMsg({type: "name", msg: "Invalid name. Must be 2-40 characters long"});
            return false;
        }
        if (!isValidValue(userData.game) || !isValidGame(userData.game)) {
            setErrorMsg({type: "game", msg: "Invalid game"});
            return false;
        }
        if (!isValidValue(userData.team) || !isValidString(userData.team, 2, 20)) {
            setErrorMsg({type: "team", msg: "Invalid team. Must be 2-20 characters long"});
            return false;
        }
        if (!isValidValue(userData.opp) || !isValidString(userData.opp, 2, 20)) {
            setErrorMsg({type: "opp", msg: "Invalid opponent. Must be 2-20 characters long"});
            return false;
        }
        if (!isValidValue(userData.matchDateTimeEST) || !isValidDateTimeEST(userData.matchDateTimeEST)) {
            setErrorMsg({type: "date", msg: "Invalid match dateTime. Must be formatted like 'YYYY-MM-DDTHH:MM:00-05:00' "});
            return false;
        }
        if (!isValidValue(userData.maps) || !isValidMaps(userData.maps)) {
            setErrorMsg({type: "maps", msg: "Invalid maps format. Must be formatted '#' or '#-#' "});
            return false;
        }
        if (!isValidValue(userData.stat) || !isValidStat(userData.stat)) {
            setErrorMsg({type: "stat", msg: "Invalid stat"});
            return false;
        }
        if (!isValidValue(userData.predicted) || !isValidNumber(userData.predicted)) {
            setErrorMsg({type: "predicted", msg: "Invalid predicted value. Must be 0-999999"});
            return false;
        }
        if (!isValidValue(userData.finished) || !isValidBoolean(userData.finished)) {
            setErrorMsg({type: "finished", msg: "Invalid finished value. Must be true or false"});
            return false;
        }
        if (!isValidValue(userData.expired) || !isValidBoolean(userData.expired)) {
            setErrorMsg({type: "expired", msg: "Invalid expired value. Must be true or false"});
            return false;
        }
        if (!isValidValue(userData.pro) || !isValidBoolean(userData.pro)) {
            setErrorMsg({type: "pro", msg: "Invalid pro value. Must be true or false"});
            return false;
        }
        // If all checks pass, clear any previous error message
        setErrorMsg({});
        return true;
    }


    function areSameExceptPredicted(newRow, oldRow) {
        const propertiesToCompare = [
            'actual',
            'expired',
            'finished',
            'img',
            'matchDateTimeEST',
        ];
    
        // Check each property except 'predicted'
        for (let prop of propertiesToCompare) {
            if (newRow[prop] !== oldRow[prop]) {
                return false;
            }
        }
    
        // Make sure all expected properties are present in both objects
        for (let prop of propertiesToCompare) {
            if (!(prop in newRow) || !(prop in oldRow)) {
                return false;
            }
        }
    
        return true;
    }


    const processRowUpdate = async (newRow, oldRow) => {
        const updatedRow = { ...newRow, isNew: false };

        let userData = { ...updatedRow, token: props.adminSession.access_token }

        // if actual is not a valid number, set it to null
        if (!isValidValue(userData.actual)) {
            userData.actual = null
            updatedRow.actual = null
        } else {
            if (!isValidNumber(userData.actual)) {
                userData.actual = null
                updatedRow.actual = null
            }
        }

        if (!verifyUserData(userData)) {
            return
        }

        // make sure finished isnt being set on accident while expired is still false
        if (userData.expired !== true && userData.finished === true) {
            setErrorMsg({type: 'edit', msg: 'Cannot be FINISHED while not EXPIRED'})
            return
        }

        // make sure finished and actual are set at the same time
        if (userData.actual !== null && (userData.expired !== true || userData.finished !== true)) {
            setErrorMsg({type: 'edit', msg: 'Must set FINISHED and ACTUAL at the same time. EXPIRED should also be true already'})
            return
        }

        // make sure finished and actual are set at the same time
        if (userData.finished === true && userData.actual == null) {
            setErrorMsg({type: 'edit', msg: 'Must set FINISHED and ACTUAL at the same time. EXPIRED should also be true already'})
            return
        }

        setOpen2(true)

        try {
            // Wait for the user to confirm the action
            await new Promise((resolve, reject) => {
                setConfirmUpdate({ resolve, reject })
            }) 

            // if promise was resolved
            // Close the dialog
            setIsLoading(true)
            setOpen2(false);
            setErrorMsg({})
            // if updating line
            if (newRow.predicted !== oldRow.predicted) {
                // check to make sure only predicted is being updated
                const same = areSameExceptPredicted(newRow, oldRow)
                if (!same || oldRow.expired === true) {
                    setErrorMsg({type: 'edit', msg: 'To edit player line, only change PREDICTED (and nothing else). EXPIRED must be false'})
                    setIsLoading(false)
                    return oldRow
                }
                const response = await adminEditPlayerLine(userData)
                if (response.status === 401) {
                    props.setAdminSession(null)
                    const { error } = await supabase.auth.signOut()
                    return
                } else if (response.status !== 200) {
                    const errorMsg = response?.errorObj?.msg ? response.errorObj.msg : 'Edit failed in database';
                    setErrorMsg({ type: 'edit', msg: errorMsg });
                    setIsLoading(false)
                    return oldRow
                }
            } 
            // if not updating line
            else {
                const response = await adminEditPlayer(userData)
                if (response.status === 401) {
                    props.setAdminSession(null)
                    const { error } = await supabase.auth.signOut()
                    return
                } else if (response.status !== 200) {
                    setErrorMsg({type: 'edit', msg: 'Edit failed in database'})
                    setIsLoading(false)
                    return oldRow
                }
            }
            setIsLoading(false)
            setUpdate(!update)
            return updatedRow

        } catch {
            // if promise was rejected
            setOpen2(false)
            setErrorMsg({})
            return oldRow
        }
    };

    const handleConfirm = () => {
        // Resolve the promise
        if (confirmUpdate) {
            confirmUpdate.resolve();
            setConfirmUpdate(null);
        }

    };

    const handleClose = () => {
        // Reject the promise
        if (confirmUpdate) {
            confirmUpdate.reject();
            setConfirmUpdate(null);
        }
    };

    
    useEffect(() => {
        if (props.tab === 1) {
            fetchBoardData()
        }
        if (props.tab === 2) {
            fetchBoardData2()
        }
    }, [props.tab, update])

    useEffect(() => {
        if (Object.keys(errorMsg).length > 0) {
          const timer = setTimeout(() => {
            setErrorMsg({})
          }, 5000)
          
          return () => clearTimeout(timer);
        }
    }, [errorMsg])

        
    return (
        <Box sx={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'start', boxSizing: 'border-box', flexDirection: 'column' }}>
            {errorMsg && errorMsg.msg ? 
                <Alert sx={{ 
                    position: 'fixed', top: '72px', width: {xs:'80%', md:'auto'}, 
                    zIndex: 99, bgcolor: '#191919', 
                    border: '2px solid #d32f2f', color: 'white', 
                    fontFamily: 'Poppins', fontSize: {xs:'14px', sm:'16px'}, fontWeight: 500,
                    alignSelf: 'center', lineHeight: 1.2, alignItems: 'center'
                }} severity="error" >
                    {errorMsg?.msg}
                </Alert> 
            : null }
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', boxSizing: 'border-box', flexDirection: 'row', mt: '32px' }}>
                <Button variant="contained" onClick={() => setOpen(true)} sx={{ 
                    bgcolor: 'rgba(168, 0, 0, 0.8)', 
                    color: 'white',
                    width: '260px',
                    height: '48px',
                    '&:hover': {
                        backgroundColor: '#A80000',
                    },
                    fontFamily: 'Montserrat',
                    fontWeight: 500,
                    fontSize: '13px',
                    borderRadius: '8px',
                    boxSizing: 'border-box'
                }}>
                    Create New Player Bet
                </Button>
            </Box>
            <AdminBoardDialog open={open} setOpen={setOpen} update={update} setUpdate={setUpdate} adminSession={props.adminSession} setAdminSession={props.setAdminSession} />
            <AdminAreYouSureDialog 
                open={open2}
                func={handleConfirm} 
                closeFunc={handleClose}
                funcString={"Save Edit"} 
                funcDesc={"Are you sure you want to edit this player?"} 
            />
            {!isLoading ? 
                <ThemeProvider theme={darkTheme}>
                    {boardData ? 
                        <Box sx={{ display: 'flex', width: '80%', gap: '24px', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'center', alignSelf: 'center', mt: '16px' }}>
                            <Box sx={{ height: 631, width: '100%', bgcolor: '#191919' }}>
                                <DataGrid
                                    sx={{ color: 'white', 
                                        '& .MuiDataGrid-filler': {
                                            backgroundColor: '#191919',
                                        }, 
                                        '& .MuiDataGrid-scrollbar--horizontal': {
                                            left: 0
                                        },
                                        '--DataGrid-containerBackground': '#191919'
                                    }}
                                    rows={rows}
                                    getRowId={(row) => row._id}
                                    columns={columns}
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={setPaginationModel}
                                    processRowUpdate={processRowUpdate}
                                    editMode='row'
                                />
                            </Box>
                        </Box>
                    :
                        null
                    }
                </ThemeProvider>
            : 
                <Skeleton variant="rounded"  width='80%' height='75%' sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', alignSelf: 'center', mt: '16px' }} />
            }
        </Box>
    )
}

export default AdminBoardDashboard