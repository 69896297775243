import React from 'react'

function Logo(props) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 133 122" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>logo</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="logo">
                <path d="M35,73.1 C33.8,72.4 32.6,71.7 31.4,71.1 L3,54.7 C1.5,53.9 0.7,52.4 0.8,50.7 C0.9,49 1.8,47.6 3.3,46.8 C4.7,46.1 6.9,45.6 8.4,45.2 C11.1,44.5 13.8,43.9 16.6,43.3 C19.4,42.7 22.3,42.2 25.1,42 C27,41.8 29.3,41.7 31.2,42.4 L32.5,37.1 C32.5,36.9 32.6,36.8 32.6,36.6 C34.8,27.8 36.9,19 39,10.2 C39,10.1 39,10.1 39.1,10 C39.9,7.2 41,6.2 43.8,5.5 L57.3,1.4 C59.1,0.8 60.9,0.5 62.7,1.3 C63.9,1.8 64.5,2.4 65.4,3.2 C65.7,3.5 66.1,3.8 66.5,4.2 C66.9,3.9 67.3,3.5 67.6,3.2 C68.5,2.4 69.1,1.8 70.3,1.3 C72.1,0.5 73.9,0.8 75.6,1.4 L89.2,5.6 C92,6.4 93,7.3 93.9,10.1 C93.9,10.2 93.9,10.2 94,10.3 L94.6,12.6 L94.6,12.6 L101.9,42.4 C103.8,41.7 106,41.8 108,42 C110.8,42.2 113.7,42.7 116.5,43.3 C119.2,43.8 122,44.5 124.7,45.2 C126.2,45.6 128.4,46.1 129.8,46.8 C131.3,47.5 132.2,49 132.3,50.7 C132.4,52.4 131.5,53.9 130.1,54.7 L101.7,71 C100.5,71.7 99.4,72.3 98.1,73 C98.7,73.2 99.2,73.6 99.7,74 C100.9,75.2 101.3,76.8 100.8,78.4 C100,81.1 98.6,82.9 97.2,85.3 C96.2,87.1 95.6,89 94.8,90.8 C90.9,100.5 86.9,106.1 78.7,112.7 C77.1,114 75.6,115.4 74,116.7 L70.5,119.7 C70.4,119.8 70.3,119.9 70.1,120 C67.5,121.8 65.8,121.8 63.2,120 C63.1,119.9 63,119.8 62.8,119.7 L59.3,116.7 C57.7,115.4 56.2,114 54.6,112.7 C46.4,106.1 42.4,100.5 38.5,90.8 C37.7,88.9 37.1,87 36.1,85.3 C34.7,82.9 33.3,81.2 32.5,78.4 C32,76.8 32.5,75.2 33.6,74 C33.9,73.6 34.4,73.3 35,73.1" id="Path" fill="#000000" fillRule="nonzero"></path>
                <path d="M66.4,79.7 C66.3,79.7 66.2,79.7 66.1,79.7 C55.3,79.7 42.9,72.5 33.6,67.2 L5.2,50.9 C6.9,50.1 27.6,44.9 30,47 C32.9,49.6 37.9,52.7 41.6,54.4 C49.5,58.2 58,59.9 66.4,59.9 C74.8,60 83.3,58.2 91.2,54.4 C94.8,52.7 99.9,49.6 102.8,47 C105.2,44.8 125.9,50.1 127.6,50.9 L99.2,67.2 C90,72.5 77.5,79.7 66.7,79.7 C66.6,79.7 66.5,79.7 66.4,79.7" id="Path" fill={props.teamColor ? props.teamColor : '#A80000'}></path>
                <path d="M65.5,116.4 L61.9,113.4 C60.2,112 58.8,110.7 57.1,109.4 C49.3,103.2 45.9,98.2 42.3,89.3 C41.3,86.9 40.9,85.3 39.6,83.2 C38.5,81.4 37.1,79.5 36.4,77.3 L41.1,78.7 L40.8,79.5 C40.4,80.5 41,81.6 42,82 L58.2,87.8 C59.2,88.2 60.3,87.6 60.7,86.6 L61.9,83.2 C63.4,83.2 64.8,83.3 66.3,83.2 C67.9,83.2 69.4,83.2 70.9,83.1 L72.1,86.5 C72.5,87.5 73.6,88 74.6,87.7 L90.8,81.9 C91.8,81.5 92.3,80.4 92,79.4 L91.7,78.5 L96.2,77.1 C95.6,79.3 94.1,81.1 93,83 C91.8,85.1 91.3,86.7 90.3,89.1 C86.7,98 83.3,103 75.5,109.2 C73.8,110.6 72.5,111.8 70.7,113.2 L67.1,116.2 C66.3,117.1 66.5,117.1 65.5,116.4" id="Path" fill="#FFFFFF"></path>
                <path d="M36.8,37.9 C39.4,27.7 40.7,22 43.3,11.5 C43.7,10.2 43.6,10.3 44.9,10 L58.5,5.8 C60.9,5 60.9,5.6 62.4,6.8 C66.8,10.3 65.9,10.3 70.3,6.8 C71.8,5.6 71.8,5 74.2,5.8 L87.8,10 C89.1,10.4 89.1,10.2 89.4,11.5 C92,22 93.3,27.7 95.9,37.9 C64.7,56.4 36.8,37.9 36.8,37.9" id="Path" fill={props.teamColor ? props.teamColor : '#A80000'}></path>
            </g>
        </g>
    </svg>
  )
}

export default Logo