import { useNavigate } from 'react-router-dom';



function RedirectPage() {

    const navigate = useNavigate();

    navigate('/login')

    return (
        <div></div>
    )
}

export default RedirectPage