import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

function EntrySummarySkeleton(props) {
  return (
    <Box sx={{ display: 'flex', width: {xs: '100%', lg:'692px'}, flexDirection: 'column', height: '100%', boxSizing: 'border-box', alignItems: 'center' }}>
        <Skeleton variant="rounded" sx={{ 
            bgcolor: 'rgba(255, 255, 255, 0.1)',
            width: {xs: '100%', md: '692px'},
            height: '150px',
            my: '8px'
        }} />
        <Skeleton variant="rounded" sx={{ 
            bgcolor: 'rgba(255, 255, 255, 0.1)',
            width: {xs: '100%', md: '692px'},
            height: '150px',
            my: '8px'
        }} />
    </Box>
  )
}
export default EntrySummarySkeleton