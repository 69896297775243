import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';

import { adminBanUser, adminEditUser, adminGetUsers } from '../APIs';
import AdminAreYouSureDialog from './AdminAreYouSureDialog';

import { supabase } from '../auth/client';

function AdminUsersDashboard(props) {

    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
        },
    });

    const [usersData, setUsersData] = useState(null);

    // used for skeleton components when fetching data from backend
    const [isLoading, setIsLoading] = useState(true)
    const [update, setUpdate] = useState(false)
    const [errorMsg, setErrorMsg] = useState({})

    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [id, setID] = useState("")
    const [confirmUpdate, setConfirmUpdate] = useState(null)

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 15,
        page: 0,
    });

    const fetchUsersData = async () => {
        setIsLoading(true)
        const userData = {
            token: props.adminSession.access_token
        }

        const data = await adminGetUsers(userData)
        if (data.status === 401) {
            props.setAdminSession(null)
            const { error } = await supabase.auth.signOut()
            return
        } else if (data.status !== 200) {
            return
        }
        setUsersData(data.users)
        setIsLoading(false)
        return
    }

    const rows = usersData

    // NO PASSWORD, IP, LOCATION, PHONE, ADDRESS, BIRTHDAY
    const columns = [
        {   
            field: '_id', 
            headerName: 'id', 
            width: 100,
        },
        {
            field: 'eaID',
            headerName: 'eaID',
            width: 60,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'email',
            width: 200,
            editable: false,
        },
        {
            field: 'firstName',
            headerName: 'firstName',
            width: 120,
            editable: false,
        },
        {
            field: 'lastName',
            headerName: 'lastName',
            width: 120,
            editable: false,
        },
        {
            field: 'member',
            headerName: 'member',
            width: 120,
            editable: false,
        },
        {
            field: 'walletBalance',
            headerName: 'WALLET BALANCE',
            type: 'number',
            width: 140,
            editable: true,
        },
        {
            field: 'winningsWalletBalance',
            headerName: 'WINNINGS BALANCE',
            type: 'number',
            width: 160,
            editable: true,
        },
        {
            field: 'promoBalance',
            headerName: 'PROMO BALANCE',
            type: 'number',
            width: 140,
            editable: true,
        },
        {
            field: 'freeEntryBalance',
            headerName: 'FREE ENTRIES',
            type: 'number',
            width: 120,
            editable: true,
        },
        {
            field: 'limit',
            headerName: 'LIMIT',
            type: 'number',
            width: 120,
            editable: true,
        },
        {
            field: 'banned',
            headerName: 'BANNED',
            width: 100,
            editable: true,
            type: 'singleSelect',
            valueOptions: [false, true]
        },
        {
            field: 'isVerified',
            headerName: 'isVerified',
            width: 120,
            editable: false
        },
        {
            field: 'bets',
            headerName: 'entries (last 5)',
            width: 190,
            editable: false,
            renderCell: (params) => (
                params.row && params.row.bets ?
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '4px', py: '6px' }}>
                        {params.row.bets.slice(-5).map((bet) => (
                            <p key={bet} style={{ margin: 0, lineHeight: 1, fontSize: '12px' }}>{bet}</p>
                        ))}
                    </Box>
                :
                    null
            )
        },
        {
            field: 'entriesWon',
            headerName: 'entriesWon',
            type: 'number',
            width: 120,
            editable: false,
        },
        {
            field: 'wagered',
            headerName: 'wagered',
            type: 'number',
            width: 120,
            editable: false,
        },
        {
            field: 'winnings',
            headerName: 'winnings',
            type: 'number',
            width: 120,
            editable: false,
        },
        {
            field: 'referrals',
            headerName: 'referrals',
            width: 80,
            editable: false,
            type: 'number'
        },
        {
            field: 'referredBy',
            headerName: 'referredBy',
            width: 220,
            editable: false,
        },
        {
            field: 'creatorCode',
            headerName: 'creatorCode',
            width: 120,
            editable: false,
        },
        {
            field: 'referralEarnings',
            headerName: 'referralEarnings',
            type: 'number',
            width: 120,
            editable: false,
        },
        {
            field: 'creatorCodeEarnings',
            headerName: 'creatorCodeEarnings',
            type: 'number',
            width: 120,
            editable: false,
        },
        {
            field: 'membershipEarnings',
            headerName: 'membershipEarnings',
            type: 'number',
            width: 120,
            editable: false,
        },
        {
            field: 'promoEarnings',
            headerName: 'promoEarnings',
            type: 'number',
            width: 120,
            editable: false,
        },
        {
            field: 'joinDate',
            headerName: 'joinDate',
            width: 120,
            editable: false,
        },
        {
            field: 'ip',
            headerName: 'ip',
            width: 180,
            editable: false,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Ban',
            width: 120,
            cellClassName: 'actions',
            getActions: ({ id }) => {
      
              return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={() => handleBanClick(id)}
                        color="inherit"
                    />,
              ];
            },
          },
    ];

    const handleBanClick = (id) => {
        setOpen(true)
        setID(id)
        return
    };

    const handleBan = async () => {
        setIsLoading(true)
        const userData = {
            id: id,
            token: props.adminSession.access_token
        }
        const response = await adminBanUser(userData)
        if (response.status === 401) {
            props.setAdminSession(null)
            const { error } = await supabase.auth.signOut()
            return
        } else if (response.status !== 200) {
            setErrorMsg({type: 'ban', msg: 'Could not ban user'})
            setUpdate(!update)
            setID("")
            setOpen(false)
            setIsLoading(false)
            return
        }
        setUpdate(!update)
        setID("")
        setErrorMsg({})
        setOpen(false)
        setIsLoading(false)
    }

    const handleClose = () => {
        setOpen(false)
        setID("")
        setErrorMsg({})
    }


    const processRowUpdate = async (newRow, oldRow) => {
        const updatedRow = { ...newRow, isNew: false };

        const userData = {
            ...updatedRow,
            token: props.adminSession.access_token
        }

        if (isNaN(updatedRow.walletBalance) || updatedRow.walletBalance < 0) {
            setErrorMsg({type: 'edit', msg: 'Invalid walletBalance value'})
            return
        }
        if (isNaN(updatedRow.winningsWalletBalance) || updatedRow.winningsWalletBalance < 0) {
            setErrorMsg({type: 'edit', msg: 'Invalid winningsWalletBalance value'})
            return
        }
        if (isNaN(updatedRow.promoBalance) || updatedRow.promoBalance < 0) {
            setErrorMsg({type: 'edit', msg: 'Invalid promoBalance value'})
            return
        }
        if (isNaN(updatedRow.freeEntryBalance) || updatedRow.freeEntryBalance < 0) {
            setErrorMsg({type: 'edit', msg: 'Invalid freeEntryBalance value'})
            return
        }
        if (isNaN(updatedRow.limit) || updatedRow.limit < 1) {
            setErrorMsg({type: 'edit', msg: 'Invalid limit value'})
            return
        }

        setOpen2(true)

        try {
            // Wait for the user to confirm the action
            await new Promise((resolve, reject) => {
                setConfirmUpdate({ resolve, reject })
            }) 

            // if promise was resolved
            // Close the dialog
            setIsLoading(true)
            setOpen2(false);
            setErrorMsg({})
            const response = await adminEditUser(userData)
            if (response.status === 401) {
                props.setAdminSession(null)
                const { error } = await supabase.auth.signOut()
                return
            } else if (response.status !== 200) {
                setErrorMsg({type: 'edit', msg: 'Edit failed in database'})
                setIsLoading(false)
                return oldRow
            }
            setIsLoading(false)
            setUpdate(!update)
            return updatedRow
        } catch {
            // if promise was rejected
            setOpen2(false)
            setErrorMsg({})
            return oldRow
        }
    };

    const handleConfirm = () => {
        // Resolve the promise
        if (confirmUpdate) {
            confirmUpdate.resolve();
            setConfirmUpdate(null);
        }
    };

    const handleClose2 = () => {
        // Reject the promise
        if (confirmUpdate) {
            confirmUpdate.reject();
            setConfirmUpdate(null);
        }
    };

    useEffect(() => {
        fetchUsersData()
    }, [update])

    useEffect(() => {
        if (Object.keys(errorMsg).length > 0) {
          const timer = setTimeout(() => {
            setErrorMsg({})
          }, 5000)

          return () => clearTimeout(timer);
        }
    }, [errorMsg])
        
    return (
        <Box sx={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'start', boxSizing: 'border-box', flexDirection: 'column' }}>
            {errorMsg && errorMsg.msg ? 
                <Alert sx={{ 
                    position: 'fixed', top: '72px', width: {xs:'80%', md:'auto'}, 
                    zIndex: 99, bgcolor: '#191919', 
                    border: '2px solid #d32f2f', color: 'white', 
                    fontFamily: 'Poppins', fontSize: {xs:'14px', sm:'16px'}, fontWeight: 500,
                    alignSelf: 'center', lineHeight: 1.2, alignItems: 'center'
                }} severity="error" >
                    {errorMsg?.msg}
                </Alert> 
            : null }
            <AdminAreYouSureDialog 
                open={open}
                func={handleBan} 
                closeFunc={handleClose}
                funcString={"Ban"} 
                funcDesc={"Are you sure you want to ban this user?"} 
            />
            <AdminAreYouSureDialog 
                open={open2}
                func={handleConfirm} 
                closeFunc={handleClose2}
                funcString={"Save Edit"} 
                funcDesc={"Are you sure you want to edit this user?"} 
            />
            {!isLoading ? 
                <ThemeProvider theme={darkTheme}>
                    {usersData ? 
                        <Box sx={{ display: 'flex', width: '80%', gap: '24px', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'center', alignSelf: 'center', mt: '16px' }}>
                            <Box sx={{ height: 631, width: '100%', bgcolor: '#191919' }}>
                                <DataGrid
                                    sx={{ color: 'white',
                                        '& .MuiDataGrid-filler': {
                                            backgroundColor: '#191919',
                                        }, 
                                        '& .MuiDataGrid-scrollbar--horizontal': {
                                            left: 0
                                        },
                                        '--DataGrid-containerBackground': '#191919',
                                    }}
                                    rows={rows}
                                    getRowHeight={() => 'auto'}
                                    getRowId={(row) => row._id}
                                    columns={columns}
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={setPaginationModel}
                                    processRowUpdate={processRowUpdate}
                                    editMode='row'
                                />
                            </Box>
                        </Box>
                    :
                        null
                    }
                </ThemeProvider>
            :
                <Skeleton variant="rounded"  width='80%' height='75%' sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', alignSelf: 'center', mt: '16px' }} />
            }
        </Box>
    )
}

export default AdminUsersDashboard