import { useEffect, useState, useLayoutEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

import GameBtn from "../components/GameBtn";
import PicksCart from "../components/PicksCart";
import CartBtn from "../components/CartBtn";
import BoardCard from "../components/BoardCard";
import SearchBar from "../components/SearchBar";

import codWhite from '../images/codWhite.png';
import haloWhite from '../images/haloWhite.svg';
import wzWhite from '../images/wzWhite.svg';
import cs2White from '../images/cs2White.svg';
import valWhite from '../images/valWhite.svg';
import apexWhite from '../images/apexWhite.svg';
import lolWhite from '../images/lolWhite.svg';
import rlWhite from '../images/rlWhite.svg';
import dota2White from '../images/dota2White.svg';
import r6White from '../images/r6White.png';

import { FaStar } from "react-icons/fa";
import { FaShoppingCart, FaUser } from 'react-icons/fa';
import { BsPersonBadge } from 'react-icons/bs';

import { getBoard, getBoardWithSearch } from "../APIs";
import MobilePicksCart from "../components/MobilePicksCart";

import BarLoader from "react-spinners/BarLoader";


function BoardPage(props) {
    const [boardData, setBoardData] = useState(null);
    const [game, setGame] = useState("COD");
    const [cartOpen, setCartOpen] = useState(false);
    const [mobileCartOpen, setMobileCartOpen] = useState(false);
    const [selectedPicks, setSelectedPicks] = useState([]);
    const [searchInput, setSearchInput] = useState("");

    const [errorMsg, setErrorMsg] = useState({});

    // used for skeleton components when fetching data from backend
    const [isLoading, setIsLoading] = useState(true);
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    const navigate = useNavigate()


    const fetchData = async () => {
        setIsLoading(true)

        const data = await getBoard()

        if (data.status !== 200) {
            setIsLoading(false)
            return
        }

        const groupedData = data.board.reduce((acc, playerData) => {
            // Extract the date part (YYYY-MM-DD) from matchDateTimeEST
            const matchDate = playerData.matchDateTimeEST.substring(0, 10);

            const key = `${playerData.name}-${playerData.team}-${matchDate}`
            const gameKey = playerData.game;
            if (!acc[gameKey]) {
                acc[gameKey] = {};
            }
            if (!acc[gameKey][key]) {
                acc[gameKey][key] = [];
            }
            acc[gameKey][key].push(playerData);
            return acc;
        }, {});
        
        // if currently selected game is on board
        if (groupedData.hasOwnProperty(game)) {
            setBoardData(groupedData[game])
            setIsFirstLoad(false)
        } 
        else {
            const games = Object.keys(groupedData)
            // if board is empty
            if (games.length === 0) {
                setBoardData([])
                setIsFirstLoad(false)
            }
            // if currently selected game is not on board and board is not empty
            else {
                const firstGame = games[0]
                setGame(firstGame)
                setBoardData(groupedData[firstGame])
            }
        }

        return
    }

    const fetchData2 = async () => {
        setIsLoading(true)

        const data = await getBoard()

        if (data.status !== 200) {
            setIsLoading(false)
            setIsFirstLoad(false)
            return
        }

        const groupedData = data.board.reduce((acc, playerData) => {
            // Extract the date part (YYYY-MM-DD) from matchDateTimeEST
            const matchDate = playerData.matchDateTimeEST.substring(0, 10);

            const key = `${playerData.name}-${playerData.team}-${matchDate}`
            const gameKey = playerData.game
            if (!acc[gameKey]) {
                acc[gameKey] = {}
            }
            if (!acc[gameKey][key]) {
                acc[gameKey][key] = []
            }
            acc[gameKey][key].push(playerData);
            return acc
        }, {})
        
        // if currently selected game is on board
        if (groupedData.hasOwnProperty(game)) {
            setBoardData(groupedData[game])
        }
        else {
            setBoardData([])
        }

        setIsLoading(false)
        setIsFirstLoad(false)
        return
    }

    // Check if value is empty, null, undefined, or NaN
    const isValidValue = (value) => {
        return value !== null && value !== undefined && value !== '';
    }

    const fetchData3 = async () => {

        // Validate the query parameter
        if (typeof searchInput !== 'string' || searchInput.length > 20 || !/^[a-zA-Z0-9-]+$/.test(searchInput)) {
            return
        }

        setIsLoading(true)

        const userData = {
            query: searchInput
        }

        const data = await getBoardWithSearch(userData)

        if (data.status !== 200) {
            setIsLoading(false)
            return
        }

        const groupedData = data.board.reduce((acc, playerData) => {
            // Extract the date part (YYYY-MM-DD) from matchDateTimeEST
            const matchDate = playerData.matchDateTimeEST.substring(0, 10);

            const key = `${playerData.name}-${playerData.team}-${matchDate}`
            const gameKey = playerData.game
            if (!acc[gameKey]) {
                acc[gameKey] = {}
            }
            if (!acc[gameKey][key]) {
                acc[gameKey][key] = []
            }
            acc[gameKey][key].push(playerData);
            return acc
        }, {})
        
        // if currently selected game is on board
        if (groupedData.hasOwnProperty(game)) {
            setBoardData(groupedData[game])
        }
        else {
            setBoardData([])
        }

        setIsLoading(false)
        return
    }

    // runs only once (defaults game to whatever game has picks)
    useLayoutEffect(() => {
        fetchData()
    }, [])

    // gets boardData for currently selected game
    useLayoutEffect(() => {
        if (!isValidValue(searchInput)) {
            fetchData2()
        }
    }, [game, searchInput])

    // gets boardData based on searchbar (0.5s delay before querying)
    useEffect(() => {
        const timer = setTimeout(() => {
            if (searchInput && isValidValue(searchInput)) {
                fetchData3()
            }
        }, 500); // Delay of 0.5 seconds

        return () => clearTimeout(timer)
    }, [searchInput]);

    useEffect(() => {
        if (Object.keys(errorMsg).length > 0) {
            const timer = setTimeout(() => {
                setErrorMsg({})
            }, 5000)

            return () => clearTimeout(timer);
        }
    }, [errorMsg])


    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', pb: '130px' }}>
            {errorMsg && errorMsg.msg ? 
                <Alert sx={{ 
                    position: 'fixed', top: '72px', width: {xs:'80%', md:'auto'}, 
                    zIndex: 99, bgcolor: '#191919', 
                    border: '2px solid #d32f2f', color: 'white', 
                    fontFamily: 'Poppins', fontSize: {xs:'14px', sm:'16px'}, fontWeight: 500,
                    alignSelf: 'center', lineHeight: 1.2, alignItems: 'center'
                }} severity="error" >
                    {errorMsg?.msg}
                </Alert> 
            : null }
                
            {!isFirstLoad ?
                <>

                    {!mobileCartOpen ?
                        <>
                            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Box sx={{ flexGrow: 1, display: 'flex', gap: '10px', flexWrap: 'nowrap', alignItems: 'center', justifyContent: {xs:'start', md:'center'}, my: {xs:'24px', md:'32px'}, mx: {xs:'6vw', md:'12px' } , overflowX: {xs:'scroll', md:'auto'}, pb: {xs:'8px', md:0} }}>
                                    <GameBtn gameTitle="COD" gameImgWhite={codWhite} game={game} setGame={setGame} />
                                    <GameBtn gameTitle="HALO" gameImgWhite={haloWhite} game={game} setGame={setGame} />
                                    <GameBtn gameTitle="WZ" gameImgWhite={wzWhite} game={game} setGame={setGame} />
                                    <GameBtn gameTitle="CS2" gameImgWhite={cs2White} game={game} setGame={setGame} />
                                    <GameBtn gameTitle="VAL" gameImgWhite={valWhite} game={game} setGame={setGame} />
                                    <GameBtn gameTitle="APEX" gameImgWhite={apexWhite} game={game} setGame={setGame} />
                                    <GameBtn gameTitle="LOL" gameImgWhite={lolWhite} game={game} setGame={setGame} />
                                    <GameBtn gameTitle="RL" gameImgWhite={rlWhite} game={game} setGame={setGame} />
                                    <GameBtn gameTitle="DOTA2" gameImgWhite={dota2White} game={game} setGame={setGame} />
                                    <GameBtn gameTitle="R6" gameImgWhite={r6White} game={game} setGame={setGame} />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: '25px' }} >
                                <Box sx={{ width: {xs:'90%', md: cartOpen ? '50%' : '60%'} }}>
                                    <Box sx={{ display: 'flex', border: 2, borderColor: 'rgba(255, 255, 255, 0.2)', bgcolor: 'rgba(255, 255, 255, 0.05)', borderRadius: '8px', textAlign: 'left', minHeight: '52px', alignItems: 'center', px: {xs:'10px', md:'20px'}, py: {xs:'5px', sm:'0px'} }}>
                                        <Box sx={{ display: 'flex', mr: {xs:'10px', md:'20px'}, minWidth: '16px', minHeight: '16px', boxSizing: 'border-box', mb: '3px' }} >
                                            <FaStar style={{ color: 'gray', width: '100%' }} />
                                        </Box>
                                        <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: {xs:'11px', lg:'13px'}, color: 'white', lineHeight: 1.1 }} >
                                            Pick 2-6 players. Choose whether you think they will perform OVER or UNDER their projection. DNP and pushes are calculated as if the player was never picked.
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: {xs:'center', sm:'space-between'}, flexDirection: {xs:'column', sm:'row'}, py: '16px', gap: {xs:'12px', sm:0}, alignItems: 'center' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                            <Typography onClick={() => navigate('/howtoplay')} sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: {xs:'12px', lg:'16px'}, color: 'white', textDecoration: 'underline', cursor: 'pointer' }} >
                                                HOW TO PLAY
                                            </Typography>
                                            <div style={{ borderRight: '2px solid #A80000',  height: '24px', margin: '0 10px' }} ></div>
                                            <a href="https://esportsagent.gg/support" target="_blank" rel="noopener noreferrer" style={{ textDecorationColor: 'white' }}>
                                                <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: {xs:'12px', lg:'16px'}, color: 'white' }} >
                                                    SUPPORT CENTER
                                                </Typography>
                                            </a>
                                        </Box>
                                        <SearchBar searchInput={searchInput} setSearchInput={setSearchInput} />
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '30px' }}>
                                        {!isLoading && !isFirstLoad && boardData && Object.entries(boardData).map(([key, playerBetsArr]) => (
                                            <BoardCard key={key} playerBetsArr={playerBetsArr} selectedPicks={selectedPicks} setSelectedPicks={setSelectedPicks} setCartOpen={setCartOpen} setErrorMsg={setErrorMsg} />
                                        ))}
                                        {!isLoading && !isFirstLoad && (!boardData || (boardData && Object.keys(boardData).length === 0)) ?
                                            <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, color: 'white', fontSize: {xs:'22px', lg:'28px'}, mt: '64px' }} >NO UPCOMING MATCHES</Typography>
                                        :
                                            null
                                        }
                                        {isLoading || isFirstLoad ?
                                            <Box sx={{ mt: '76px' }}>
                                                <BarLoader
                                                    color="#A80000"
                                                    loading="true"
                                                    height={10}
                                                    width={260}
                                                    data-testid="loader"
                                                />
                                            </Box>
                                        :
                                            null
                                        }
                                    </Box>
                                </Box>
                                <Box sx={{ width: cartOpen ? '400px' : '50px', mt: cartOpen ? '0px' : '150px', display: {xs:'none', md:'block'} }}>
                                    {
                                        cartOpen ? 
                                            <PicksCart setErrorMsg={setErrorMsg} selectedPicks={selectedPicks} setSelectedPicks={setSelectedPicks} setCartOpen={setCartOpen} cartOpen={cartOpen} session={props.session} setSession={props.setSession} validLocation={props.validLocation} detectedState={props.detectedState} />
                                        :
                                            <Box sx={{ position: 'sticky', top: '90px' }}>
                                                <CartBtn setCartOpen={setCartOpen} cartOpen={cartOpen} />
                                            </Box>
                                    }
                                </Box>
                            </Box>
                        </>
                    :
                        <MobilePicksCart setErrorMsg={setErrorMsg} selectedPicks={selectedPicks} setSelectedPicks={setSelectedPicks} setCartOpen={setCartOpen} cartOpen={cartOpen} session={props.session} setSession={props.setSession} validLocation={props.validLocation} detectedState={props.detectedState}/>
                    }
                    <Box sx={{ bgcolor: 'black', position: 'fixed', bottom: '0px', width: '100%', height: '60px', display: {xs:'flex', md:'none'}, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', zIndex: '99', }}>
                        <Box sx={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '2px dashed #A80000', height: '90%', flexDirection: 'column' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '5px' }}>
                                {selectedPicks.map((bet, index) => (
                                    <FaUser color="#A80000" size={18} key={index} />
                                ))}
                            </Box>
                            <Typography sx={{ lineHeight: 1, fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 500, mx: '10px', mt: selectedPicks.length > 0 ? '5px' : 0, color: 'white' }}>{selectedPicks.length} SELECTED</Typography>
                        </Box>
                        <Box sx={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button 
                                onClick={() => setMobileCartOpen(!mobileCartOpen)}
                                sx={{ 
                                    width: '100%', 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    alignItems: 'center', 
                                    flexDirection: 'column' 
                                }}
                            >
                                {mobileCartOpen ?
                                    <>
                                        <BsPersonBadge color='white' size={20}/>
                                        <Typography sx={{ color: 'white', lineHeight: 1, fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 500, mt: '5px' }}>Board</Typography>
                                    </>
                                :
                                    <>
                                        <FaShoppingCart color='white' size={20}/>
                                        <Typography sx={{ color: 'white', lineHeight: 1, fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 500, mt: '5px' }}>Cart</Typography>
                                    </>
                                }
                            </Button>
                        </Box>
                    </Box>
                </>
            :
                null
            }
        </Box>
    )
}
  
export default BoardPage;